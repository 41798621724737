import { Tag } from 'antd';
import requestService from 'api/request';
import { formatNumber, open1 } from 'lib/helpers/format';
import React, { useEffect, useState } from 'react'

const HistoryBet = () => {
    const [histories, setHistories] = useState<any>([]);

    const getHistories = async () => {
        try {
            const res = await requestService.get("/profile/history-transaction");
            if (res && res.data) {
                setHistories(res?.data?.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getHistories();
    }, []);


    const getOpen = (id: any) => {
        const data = open1.find((i) => i.id === id);
        return data;
    };
    const namebyType = {
        '2_so_dau_giai_dac_biet': '2 số đầu giải đặc biệt',
        '2_cang_dac_biet': '2 số cuối giải đặc biệt',
        '2_cang_bao_lo': '2 số đuôi các giải',
        '3_so_dau_giai_dac_biet': '3 số đầu giải đặc biệt',
        '3_cang_dac_biet': '3 số cuối giải đặc biệt',
        '3_cang_bao_lo': '3 số đuôi các giải',
    } as any;

    return (
        <div className="place__bet bet__history box__shadowbg">
            <div className="panel__tbl">
                <div className="panel__tbl--top">
                    <div className="panel__title">Lịch sử cược</div>
                    <div className="panel__options">
                        <a id="reloadHistory" onClick={getHistories}>
                            <span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="size-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                                    />
                                </svg>
                            </span>
                            Làm mới
                        </a>
                    </div>
                </div>
                <div className="history__detail tbl__scroll" id="history__detail">
                    <div className="tbl__header">
                        <div className="tbl">
                            <div className="tbl__row">

                                <div className="tbl__col w-10">Xổ số</div>
                                <div className="tbl__col w-10">Kiểu cược</div>
                                <div className="tbl__col w-14">Thời gian mua</div>
                                <div className="tbl__col w-14">Số cược</div>
                                <div className="tbl__col w-8">Tổng cược</div>

                                <div className="tbl__col w-12">Tiền cược</div>
                                <div className="tbl__col w-11">Trạng thái</div>
                            </div>
                        </div>
                    </div>
                    <div className="grid__tbl" style={{ position: "relative" }}>
                        <div className="gid__tbl--inner">
                            <div className="tbl" id="history__bet--record">
                                {
                                    histories?.map((i: any) => (
                                        <div className="tbl__empty tbl__row">
                                            <div className="tbl__col w-10">
                                                {
                                                    getOpen(+i?.bet_id)?.name
                                                }
                                            </div>
                                            <div className="tbl__col w-10">{namebyType[i?.transaction_type]}</div>
                                            <div className="tbl__col w-14">{new Date(i?.createdAt)?.toLocaleDateString()}</div>
                                            <div className="tbl__col w-14">{i?.bet_value?.join(',')}</div>
                                            <div className="tbl__col w-8">{formatNumber(i?.value * i?.bet_value?.length)}</div>

                                            <div className="tbl__col w-12">{formatNumber(i?.value)}</div>
                                            <div className="tbl__col w-11">
                                                {i?.transaction_status === 'pending' ? <Tag color='orange-inverse'>Đang chờ</Tag>
                                                    : i?.transaction_status === 'finish' ? <Tag color='green-inverse'>Thắng</Tag>
                                                        :
                                                        <Tag color='red-inverse'>Thua</Tag>

                                                }
                                            </div>
                                        </div>
                                    ))
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HistoryBet