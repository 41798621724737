import { message, Spin } from "antd";
import requestService from "api/request";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { onSetUser } from "store/app";

export const Persional = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useAppSelector((state) => state.app) as any;
  const [form, setForm] = useState<any>();
  const dispatch = useAppDispatch();

  const handleSubmit = async () => {
   

    setLoading(true);
    try {
      const res = await requestService.post("/profile/update", {
        data: {
          ...form,
        },
      });

      if (res && res.data) {
        message.success("Đã update thành công");
        getUser();
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message);
    }
    setLoading(false);
  };

  const getUser = async () => {
    try {
      const res = await requestService.get("/profile/me");
      if (res && res.data) {
        dispatch(onSetUser(res?.data?.data));
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  useEffect(() => {
    if (user) {
      setForm({
        user_name: user?.user_name,
      });
    }
  }, [user]);
  return (
    <div className="tab-content box__shadowbg">
      <Spin fullscreen spinning={loading} />
      <div className="clearfix" id="user__infor">
        <div className="avatar__change">
          <form
            action="/UploadAvatarImage.shtml"
            method="post"
            encType="multipart/form-data"
          >
            <a className="edit__avatar">
              <div className="wrap__img abc">
                <img
                  src="/images/avatar/default_avatar.jpg"
                  className="img-circle"
                />
              </div>
              <span className="icon-new-message" />{" "}
              <input
                id="avatar"
                type="file"
                name="file"
                className="upload__avatar"
              />
            </a>
            <input
              type="submit"
              defaultValue="Cập nhật"
              className="btn__primary"
            />
            <p className="mgT-15 fs-13 text-color">
              ( dung lượng ảnh tối đa 500KB )
            </p>
          </form>
        </div>
        <form
          id="nickname"
          name="nickname"
          method="post"
          action="/page/PersonPwd.shtml"
          encType="application/x-www-form-urlencoded"
        >
          <input type="hidden" name="nickname" defaultValue="nickname" />
          <div className="mgB-40">
            <p className="fs-24 font-300 mgB-25 color-5c5146">Biệt danh</p>
            <div className="frm__item">
              <label className="font-400 fs-16 color-8e8071 w-[20%] text-right pdR-20">
                Tên đăng nhập:
              </label>
              <div className="iput w-[40%]">
                <input
                  id="nickname:loginnameReadOnly"
                  type="text"
                  name="nickname:loginnameReadOnly"
                  defaultValue={user?.email}
                  disabled
                  className="h-[40px] w-100"
                />
              </div>
            </div>
            <div id="nickname_form" className="frm__item">
              <label className="font-400 fs-16 color-8e8071 w-[20%] text-right pdR-20">
                Biệt danh:
              </label>
              <div className="iput w-[40%]">
                <input
                  id="nickname:nickname"
                  type="text"
                  name="nickname:nickname"
                  value={form?.user_name}
                  onChange={(e) =>
                    setForm({ ...form, user_name: e.target.value })
                  }
                  className="h-[40px] w-100 required email"
                  maxLength={20}
                  size={20}
                />
              </div>
            </div>
            <div className="frm__item frm__btn w-60 text-right">
              <label className="font-400 fs-16 color-8e8071 w-[20%] text-right pdR-20" />
              <input
                className="btn__primary d-ib pdT-10 pdB-10 w--180 fs-20"
                id="nickname:j_id104"
                name="nickname:j_id104"
                defaultValue="Lưu"
                onClick={handleSubmit}
                type="button"
              />
            </div>
          </div>
          <input
            type="hidden"
            name="javax.faces.ViewState"
            defaultValue="j_id10"
          />
        </form>
        <form
          id="password"
          name="password"
          method="post"
          action="/page/PersonPwd.shtml"
          encType="application/x-www-form-urlencoded"
        >
          <input type="hidden" name="password" defaultValue="password" />
          <div id="password_form" className="mgB-40">
            <p className="fs-24 font-300 mgB-25 color-5c5146">Mật Khẩu</p>
            <div className="frm__item">
              <label className="font-400 fs-16 color-8e8071 w-[20%] text-right pdR-20">
                Mật khẩu cũ:
              </label>
              <div className="iput w-[40%]">
                <input
                  id="password:Pwd"
                  type="password"
                  name="password:Pwd"
                  defaultValue=""
                  maxLength={20}
                  size={20}
                  className="h-[40px] w-100 required"
                />
                <p className="error">Thông báo lỗi !!!</p>
              </div>
            </div>
            <div className="frm__item">
              <label className="font-400 fs-16 color-8e8071 w-[20%] text-right pdR-20">
                Mật khẩu mới:
              </label>
              <div className="iput w-[40%]">
                <input
                  id="password:newPwd1"
                  type="password"
                  name="password:newPwd1"
                  defaultValue=""
                  maxLength={20}
                  size={20}
                  className="h-[40px] w-100 required"
                />
                <p className="error">Thông báo lỗi !!!</p>
              </div>
            </div>
            <div className="frm__item">
              <label className="font-400 fs-16 color-8e8071 w-[20%] text-right pdR-20">
                Nhập lại mật khẩu mới:
              </label>
              <div className="iput w-[40%]">
                <input
                  id="password:newPwd2"
                  type="password"
                  name="password:newPwd2"
                  defaultValue=""
                  maxLength={20}
                  size={20}
                  className="h-[40px] w-100 required"
                />
                <p className="error">Thông báo lỗi !!!</p>
              </div>
            </div>
            <div className="frm__item frm__btn w-60 text-right">
              <label className="font-400 fs-16 color-8e8071 w-[20%] text-right pdR-20" />
              <input
                className="btn__primary d-ib pdT-10 pdB-10 w--180 fs-20"
                id="password:j_id123"
                name="password:j_id123"
                defaultValue="Lưu"
                type="button"
              />
            </div>
          </div>
          <input
            type="hidden"
            name="javax.faces.ViewState"
            defaultValue="j_id10"
          />
        </form>
        <form
          id="checkPassword"
          name="checkPassword"
          method="post"
          action="/page/PersonPwd.shtml"
          encType="application/x-www-form-urlencoded"
        >
          <input
            type="hidden"
            name="checkPassword"
            defaultValue="checkPassword"
          />
        </form>
      </div>
    </div>
  );
};
