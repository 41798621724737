import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Confetti from "react-confetti";

const Game = () => {

    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);

    const [isVisible, setVisible] = useState(null);
    const [bet, setBet] = useState(null);
    const [profile, setProfile] = useState(null);
    const [second, setSecond] = useState(0);
    const [minute, setMinute] = useState(3);
    const [start, setStart] = useState(false);
    const [dulieunhap, setDulieunhap] = useState(new Date());
    const [update, setUpdate] = useState(0);
    const [err, setErr] = useState(null);
    const [active, setActive] = useState(null);
    const date = new Date();
    const currentMinute = date.getMinutes();
    const currentSecond = date.getSeconds();
    const [state, setState] = useState<any>(null);
    const [total, setTotal] = useState<any>(null);
    const [isShow, setShow] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [choose, setChoose] = useState(null);
    const onChoose = (e: any) => {
        setChoose(e);
        setActive(e);
        setShowPopup(true);
    };

    const handleClose = () => {
        setShowPopup(false);
    };

    const handleBackdropClick = (event: any) => {
        if (event.target === event.currentTarget) {
            setShowPopup(false);
        }
    };

    const [isOpen, setIsOpen] = useState(false);

    const openPopup = () => {
        setIsOpen(true);
    };

    const closePopup = () => {
        setIsOpen(false);
    };

    const [quantity, setQuantity] = useState(100);
    const handleSetValue = (value: any) => {
        setQuantity(value);
    };

    const resetForm = () => {
        setQuantity(100);
        setActive(null);
        setState(null);
        setShowPopup(false);
    }
    return (
        <>
            <div className="app1">
                <div style={{ background: "linear-gradient(to top, #fecf6b, #c79427)", padding: "10px 0" }}>
                    <div data-v-69b3b4a1="" data-v-0f9d40fc="" className="lottery-header theme-main-bg theme-header-bottom" data-v-435dc89a="">
                        <Link to="/">
                            {" "}
                            <div data-v-69b3b4a1="" className="back-btn"></div>
                        </Link>
                        <section data-v-82095748="" data-v-0f9d40fc="" className="lottery-timer theme-main-bg-5 theme-after-border-color-1" data-v-69b3b4a1="">
                            <div data-v-82095748="" className="label">
                                <p data-v-82095748="" className="issue theme-color-white">
                                    ID: {3000}
                                </p>
                            </div>
                            <div data-v-82095748="" className="content-box">
                                <div
                                    style={{
                                        textAlign: "center",
                                        width: "100%",
                                        marginBottom: "-8px"
                                    }}>
                                    {" "}
                                    Number <span className="sid">{16667877 || "----"}</span>{" "}
                                </div>
                                <div data-v-82095748="" className="content theme-color-white">
                                    <span data-v-82095748=""></span>
                                    <span data-v-82095748="" className="time">
                                        00
                                    </span>
                                    <span data-v-82095748="" className="time shengtime1">
                                        0{1}
                                    </span>
                                    <span data-v-82095748="" className="time shengtime2">
                                        {13 < 10 ? "0" : ""}
                                        {6}
                                    </span>
                                </div>
                            </div>
                        </section>
                        <div style={{ width: "100px", textAlign: "right", padding: "0 10px" }}>
                            <div className="info-icon" onClick={openPopup}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6" style={{ width: '28px' }}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                </svg>

                            </div>
                        </div>
                    </div>
                    <section data-v-4a0db828="" data-v-0f9d40fc="" className="lottery-result-box" data-v-435dc89a="">
                        <div data-v-4a0db828="" className="lottery-result">
                            <div data-v-4a0db828="" className="label">
                                <p data-v-4a0db828="" className="issue theme-color-white">
                                    Number: <span className="sid_ed">{total ? total[0]?.id_bet : "----"}</span>
                                </p>
                            </div>
                            <div data-v-4a0db828="" className="content">
                                {total != null && (
                                    <ul data-v-4a0db828="" className="clear-fix list">
                                        <li data-v-4a0db828="" className="num-item ball   i0 number1 numberlist">
                                            {total[0].result.split(" ")[0]}
                                        </li>
                                        <li data-v-4a0db828="" className="num-item ball  i1 number2 numberlist">
                                            {total[0].result.split(" ")[1]}
                                        </li>
                                        <li data-v-4a0db828="" className="num-item ball  i2 number3 numberlist">
                                            {total[0].result.split(" ")[2]}
                                        </li>
                                        <li data-v-4a0db828="" className="num-item ball  i3 number4 numberlist">
                                            {total[0].result.split(" ")[3]}
                                        </li>
                                        <li data-v-4a0db828="" className="num-item ball  i4 number5 numberlist">
                                            {total[0].result.split(" ")[4]}
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                    </section>
                </div>
                <div className="record_bet">
                    <nav data-v-260e3e17="" data-v-0f9d40fc="" className="tab-box lottery-game-tab" data-v-435dc89a="">
                        <div data-v-260e3e17="" className="tab-item" onClick={() => navigate("/game1")}>
                            PHÒNG 1
                        </div>
                        <div data-v-260e3e17="" className="tab-item tab-item-active">
                            PHÒNG 3
                        </div>
                        <div data-v-260e3e17="" className="tab-item" onClick={() => navigate("/game5")}>
                            PHÒNG 5
                        </div>
                    </nav>
                    <div className="bet-input" style={{ borderLeft: "4px solid #387bea", padding: "10px" }}>
                        <div style={{ color: "#387bea", width: "40px" }}>&nbsp;</div>
                        <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <div
                                style={{
                                    background: "#a9bef5",
                                    color: "#e23636",
                                    padding: "8px 5px",
                                    borderRadius: "5px",
                                    fontSize: "14px",
                                    fontWeight: "bold"
                                }}>
                                {localStorage.getItem("banner") == "3" ? "NƠI TÌNH YÊU BẮT ĐẦU" : "UY TÍN TẠO NÊN THƯƠNG HIỆU"}
                            </div>
                        </div>
                        <div style={{ width: "40px", textAlign: "right" }} onClick={() => window.location.reload()}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6" style={{ width: '28px' }}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                            </svg>

                        </div>
                    </div>
                    <ul data-v-0f9d40fc="" className="lottery-code-panel-body">
                        <li data-v-0f9d40fc="" className="ybcp-num-item">
                            <span
                                data-v-0f9d40fc=""
                                className={`lottery-code-item-name ${active == 1 && "active"}`}
                                onClick={(e) => {
                                    onChoose(1);
                                }}>
                                {localStorage.getItem("banner") == "3" ? "KẾT NỐI" : "NHẬP"}
                            </span>
                        </li>
                        <li data-v-0f9d40fc="" className="ybcp-num-item">
                            <span
                                data-v-0f9d40fc=""
                                className={`lottery-code-item-name ${active == 2 && "active"}`}
                                onClick={(e) => {
                                    onChoose(2);
                                }}>
                                {localStorage.getItem("banner") == "3" ? "YÊU THƯƠNG" : "XUẤT"}
                            </span>
                        </li>
                        {/*<li data-v-0f9d40fc="" className="ybcp-num-item">
                            <span
                                data-v-0f9d40fc=""
                                className={`lottery-code-item-name ${active == 3 && "active"}`}
                                onClick={(e) => {
                                    onChoose(3);
                                }}>
                                LÊN
                                <span data-v-0f9d40fc="" className="lottery-code-item-odds">
                                    1.99{" "}
                                </span>
                            </span>
                        </li>
                        <li data-v-0f9d40fc="" className="ybcp-num-item">
                            <span
                                data-v-0f9d40fc=""
                                className={`lottery-code-item-name ${active == 4 && "active"}`}
                                onClick={(e) => {
                                    onChoose(4);
                                }}>
                                XUỐNG
                                <span data-v-0f9d40fc="" className="lottery-code-item-odds">
                                    1.99{" "}
                                </span>
                            </span>
                            </li>*/}

                        <img src={'https://exquisite.international/static/media/banner3.94f929de94167017b385.png'} />
                    </ul>
                </div>
            </div>
            {isOpen && (
                <div className="popup-hd">
                    <div className="popup-hd-header">
                        <h3>QUY ĐỊNH</h3>
                        <div className="popup-hd-close" onClick={closePopup}>
                            &times;
                        </div>
                    </div>
                    <div className="popup-hd-content">
                        <p>EXQUISITE nghiêm cấm mọi hình thức đặt cược gian lận, nếu bị phát hiện chúng tôi có quyền thu hồi tất cả số điểm thưởng và không có thông báo nào.</p>
                        <p>Nếu quý khách có bất kỳ ý kiến hoặc kiến nghị nào vui lòng lựa chọn mục "CSKH" và để lại lời nhắn.</p>
                        <p>
                            Để đảm bảo trang web được hoạt động lâu dài cũng như bắt buộc duy trì các hoạt động thuế cho doanh nghiệp và nhà nước đối với các khách hàng tiến hành
                            rút điểm từ trang web theo hạn mức dưới đây.
                        </p>
                        <p>Khi rút điểm cần thực hiện đóng phí duy trì theo hạn mức như sau:</p>
                        <ul>
                            <li>- Hạn mức rút điểm tài khoản từ 200 triệu (tương ứng với 15% phí)</li>
                            <li>- Hạn mức rút điểm tài khoản từ 201-500 triệu (tương ứng với 20% phí)</li>
                            <li>- Hạn mức rút điểm tài khoản trên 500 triệu (tương ứng với 30% phí)</li>
                        </ul>
                    </div>
                </div>
            )}
            {showConfetti && (
                <Confetti
                    style={{
                        width: "100vw",
                        height: "100vh",
                        maxWidth: "540px",
                        zIndex: "999999"
                    }}
                />
            )}
            {showPopup && (
                <form className="lottery-bet-input">
                    <div className="chip-row">
                        <div className={`chip ${quantity == 100 ? "active" : ""}`} onClick={() => handleSetValue(100)}>
                            100
                        </div>
                        <div className={`chip ${quantity == 500 ? "active" : ""}`} onClick={() => handleSetValue(500)}>
                            500
                        </div>
                        <div className={`chip ${quantity == 1000 ? "active" : ""}`} onClick={() => handleSetValue(1000)}>
                            1000
                        </div>
                        <div className={`chip ${quantity == 10000 ? "active" : ""}`} onClick={() => handleSetValue(10000)}>
                            10000
                        </div>
                        <div className={`chip ${quantity == 20000 ? "active" : ""}`} onClick={() => handleSetValue(20000)}>
                            20000
                        </div>
                    </div>
                    <div className="bet-input">
                        <div style={{ cursor: "pointer" }} onClick={resetForm}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#555"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                style={{ position: "relative", top: "2px", margin: "0 3px 0 0" }}>
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M4 7l16 0" />
                                <path d="M10 11l0 6" />
                                <path d="M14 11l0 6" />
                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                            </svg>
                            Đóng cửa
                        </div>
                        <div>
                            <input type="number" value={quantity} onChange={(e: any) => setQuantity(e.target.value)} name="money" />
                        </div>
                        <div>
                            <button type="submit">Xác nhận</button>
                        </div>
                    </div>
                    <div className="bet-input" style={{ color: "#000", fontWeight: "bold" }}>
                        <div>Number: {bet ? 11111 : "----"}</div>
                        <div>Số dư: {Math.floor(10000).toLocaleString()}</div>
                    </div>
                </form>
            )}

        </>
    )
}

export default Game