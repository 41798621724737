import { createSlice } from '@reduxjs/toolkit'



const initialState = {
  appGlobal:null,
  socket: null,
  user: null,
  loadingScreen: false,
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
      onSetAppGlobal:(state, action) => {
        state.appGlobal = action.payload;
      },
      onSetUser: (state, action) => {
        state.user = action.payload;
      },
      onLoadingScreen: (state, action) => {
        state.loadingScreen = action.payload;
      },
  },
})

export const {
  onSetAppGlobal,
  onSetUser,
  onLoadingScreen
} = appSlice.actions

export default appSlice.reducer
