export function formatNumber(value: any) {
  if (isNaN(value)) {
    return "0";
  }

  const number = parseFloat(value);

  if (number === 0) {
    return "0";
  }

  // Check if the integer part is 0 and the decimal part has more than 5 leading zeros
  const [integerPart, decimalPart] = value?.toString().split(".");
  if (integerPart === "0" && decimalPart && /^0{5,}/.test(decimalPart)) {
    return number.toExponential();
  }

  // Format number with commas
  return number.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 20, // Adjust this as needed
  });
}

export function formatVolume(volume_24h: number) {
  if (volume_24h >= 1e9) {
    return (volume_24h / 1e9)?.toFixed(2) + " B";
  } else if (volume_24h >= 1e6) {
    return (volume_24h / 1e6)?.toFixed(2) + " M";
  } else if (volume_24h >= 1e3) {
    return (volume_24h / 1e3)?.toFixed(2) + " K";
  } else {
    return volume_24h?.toFixed(2);
  }
}

export const validateEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i; // Added the 'i' flag for case insensitivity
  return emailRegex.test(email);
};

export const validatePassword = (password: string) => {
  return password.length >= 6;
};

export const shortenHexString = (hexString: string) => {
  // Lấy hai số đầu và hai số cuối của chuỗi hex
  const firstDigits = hexString?.substring(0, 3);
  const lastDigits = hexString?.substring(hexString.length - 3);

  // Tạo chuỗi kết quả
  const result = `${firstDigits}...${lastDigits}`;

  return result;
};

export function getUrl(pathname: string) {
  let { hostname, protocol, port } = window.location;
  let portString = "";
  if (port !== "80" && port !== "443" && port !== "") {
    portString = ":" + port;
  }
  return protocol + "//" + hostname + portString + "/" + pathname;
}

export function getJSONFromUrl() {
  try {
    let search = window.location.search.substring(1);
    let json = JSON.parse(
      '{"' +
        decodeURI(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );
    let result = {} as any;
    Object.keys(json).forEach((key: any) => {
      result[decodeURIComponent(key)] = decodeURIComponent(json[key]);
    });
    return result;
  } catch (e) {
    return {};
  }
}

export const open1 = [
  { id: 1, name: "Thái Bình", href: "/mien-bac" },
  { id: 2, name: "Kon Tum", href: "/mien-trung/xo-so-kon-tum" },
  { id: 3, name: "Khánh Hòa", href: "/mien-trung/xo-so-khanh-hoa" },
  { id: 4, name: "Kiên Giang", href: "/mien-nam/xo-so-kien-giang" },
  { id: 5, name: "Lâm Đồng", href: "/mien-nam/xo-so-lam-dong" },
  { id: 6, name: "Tiền Giang", href: "/mien-nam/xo-so-tien-giang" },
  { id: 7, name: "Hà Nội", href: "/mien-bac" },
  { id: 8, name: "Phú Yên", href: "/mien-trung/xo-so-phu-yen" },
  { id: 9, name: "Thừa Thiên Huế", href: "/mien-trung/xo-so-thua-thien-hue" },
  { id: 10, name: "Cà Mau", href: "/mien-nam/xo-so-ca-mau" },
  { id: 11, name: "Đồng Tháp", href: "/mien-nam/xo-so-dong-thap" },
  { id: 12, name: "TP.Hồ Chí Minh", href: "/mien-nam/xo-so-ho-chi-minh" },
  { id: 13, name: "Quảng Bình", href: "/mien-bac" },
  { id: 14, name: "Đắk Lắk", href: "/mien-trung/xo-so-dak-lak" },
  { id: 15, name: "Quảng Nam", href: "/mien-trung/xo-so-quang-nam" },
  { id: 16, name: "Bạc Liêu", href: "/mien-nam/xo-so-bac-lieu" },
  { id: 17, name: "Bến Tre", href: "/mien-nam/xo-so-ben-tre" },
  { id: 18, name: "Vũng Tàu", href: "/mien-nam/xo-so-vung-tau" },
  { id: 19, name: "Bắc Ninh", href: "/mien-bac" },
  { id: 20, name: "Khánh Hòa", href: "/mien-trung/xo-so-khanh-hoa" },
  { id: 21, name: "Đà Nẵng", href: "/mien-trung/xo-so-da-nang" },
  { id: 22, name: "Cần Thơ", href: "/mien-nam/xo-so-can-tho" },
  { id: 23, name: "Đồng Nai", href: "/mien-nam/xo-so-dong-nai" },
  { id: 24, name: "Sóc Trăng", href: "/mien-nam/xo-so-soc-trang" },
  { id: 25, name: "Hà Nội", href: "/mien-bac" },
  { id: 26, name: "Bình Định", href: "/mien-trung/xo-so-binh-dinh" },
  { id: 27, name: "Quảng Bình", href: "/mien-trung/xo-so-quang-binh" },
  { id: 28, name: "Quảng Trị", href: "/mien-trung/xo-so-quang-tri" },
  { id: 29, name: "An Giang", href: "/mien-nam/xo-so-an-giang" },
  { id: 30, name: "Bình Thuận", href: "/mien-nam/xo-so-binh-thuan" },
  { id: 31, name: "Tây Ninh", href: "/mien-nam/xo-so-tay-ninh" },
  { id: 32, name: "Hải Phòng", href: "/mien-bac" },
  { id: 33, name: "Gia Lai", href: "/mien-trung/xo-so-gia-lai" },
  { id: 34, name: "Ninh Thuận", href: "/mien-trung/xo-so-ninh-thuan" },
  { id: 35, name: "Bình Dương", href: "/mien-nam/xo-so-binh-duong" },
  { id: 36, name: "Trà Vinh", href: "/mien-nam/xo-so-tra-vinh" },
  { id: 37, name: "Vĩnh Long", href: "/mien-nam/xo-so-vinh-long" },
  { id: 38, name: "Nam Định", href: "/mien-bac" },
  { id: 39, name: "Đà Nẵng", href: "/mien-trung/xo-so-da-nang" },
  { id: 40, name: "Đắk Nông", href: "/mien-trung/xo-so-dak-nong" },
  { id: 41, name: "Quảng Ngãi", href: "/mien-trung/xo-so-quang-ngai" },
  { id: 42, name: "TP. Hồ Chí Minh", href: "/mien-nam/xo-so-ho-chi-minh" },
  { id: 43, name: "Bình Phước", href: "/mien-nam/xo-so-binh-phuoc" },
  { id: 44, name: "Hậu Giang", href: "/mien-nam/xo-so-hau-giang" },
  { id: 45, name: "Long An", href: "/mien-nam/xo-so-long-an" },
];
