/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { message, Spin, Tag } from "antd";
import requestService from "api/request";
import clsx from "clsx";
import useBreakpoint from "hooks/useBreakpoint";
import { formatNumber, open1 } from "lib/helpers/format";
import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { onSetUser } from "store/app";

const numbers = Array.from({ length: 10 }, (_, i) => i);
const namebyType = {
  "2_so_dau_giai_dac_biet": "2 số đầu giải đặc biệt",
  "2_cang_dac_biet": "2 số cuối giải đặc biệt",
  "2_cang_bao_lo": "2 số đuôi các giải",
  "3_so_dau_giai_dac_biet": "3 số đầu giải đặc biệt",
  "3_cang_dac_biet": "3 số cuối giải đặc biệt",
  "3_cang_bao_lo": "3 số đuôi các giải",
} as any;
const typeMatch = {
  match_2: [
    {
      name: "2 số đầu giải đặc biệt",
      type: "2_so_dau_giai_dac_biet",
    },
    {
      name: "2 số cuối giải đặc biệt",
      type: "2_cang_dac_biet",
    },
    {
      name: "2 số đuôi các giải",
      type: "2_cang_bao_lo",
    },
  ],
  match_3: [
    {
      name: "3 số đầu giải đặc biệt",
      type: "3_so_dau_giai_dac_biet",
    },
    {
      name: "3 số cuối giải đặc biệt",
      type: "3_cang_dac_biet",
    },
    {
      name: "3 số đuôi các giải",
      type: "3_cang_bao_lo",
    },
  ],
} as any;
const Lottery = () => {
  const breakpoint = useBreakpoint();
  const [histories, setHistories] = useState<any>([]);
  const { state } = useLocation();
  const { id } = useParams();
  const [openPrize, setOpenPrize] = useState<any>([]);
  const [selectedStation, setSelectedStation] = useState<any>();
  const [resultLatest, setResultLatest] = useState<any>();
  const [match, setMatch] = useState<string>("match_2");
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [betValue, setBetValue] = useState<Array<any>>([]);
  const [numsRessult, setNumsResult] = useState<any>();
  const [valueSelect, setvalueSelect] = useState<any>({
    donvi: [],
    chuc: [],
    tram: [],
  });
  const { user } = useAppSelector((state) => state.app) as any;
  const dispatch = useAppDispatch();
  const [selectedType, setSelectedType] = useState<any>();

  const getRatePoint = () => {

    
    if (
      selectedType?.type === "2_so_dau_giai_dac_biet" ||
      selectedType?.type === "2_cang_dac_biet" ||
      selectedType?.type === "3_so_dau_giai_dac_biet" ||
      selectedType?.type === "3_cang_dac_biet"
    ) {
      return 1000;
    }

    if (id === "xsmn" || id === "xsmt") {
      if (match === "match_2") return 18000;
      if (match === "match_3") return 17000;
    } else {
      if (match === "match_2") return 27000;
      if (match === "match_3") return 23000;
    }
    return 1;
  };

  const onChange = (type: any, value: any) => {
    setvalueSelect((prev: any) => {
      const newArray = prev[type].includes(value)
        ? prev[type].filter((item: any) => item !== value) // Remove value if it exists
        : [...prev[type], value]; // Add value if it doesn't exist

      return {
        ...prev,
        [type]: newArray,
      };
    });
  };

  function calculateTimeUntilHour(targetHour: number) {
    const now = new Date();
    const target = new Date();

    // Set the target hour with minutes and seconds to 0
    target.setHours(targetHour, 0, 0, 0);

    // If the target time has already passed today, move the target to tomorrow
    if (target <= now) {
      target.setDate(target.getDate() + 1);
    }

    return target.getTime(); // Return the time in milliseconds
  }

  const getPrizeOpening = async () => {
    try {
      const res = await requestService.get("/auth/prize-opening");
      if (res && res.data) {
        const openPrize = res.data.data;
        if (id === "xsmn") return setOpenPrize(openPrize?.southern[0]);
        if (id === "xsmt") return setOpenPrize(openPrize?.central[0]);
        if (id === "xsmb") return setOpenPrize(openPrize?.northern[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) getPrizeOpening();
  }, [id]);

  useEffect(() => {
    if (openPrize && !!openPrize?.length) setSelectedStation(openPrize[0]);
  }, [openPrize]);

  const getResultLatestLottery = async (url: string) => {
    try {
      const res = await requestService.get("/auth/crawl_url_lottery", {
        params: {
          url,
          type: id,
        },
      });
      if (res && res.data) {
        setResultLatest(res.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedStation) {
      getResultLatestLottery(selectedStation?.href);
    }
  }, [selectedStation]);

  useEffect(() => {
    if (!!resultLatest) {
      const tempData = {} as any;

      for (let i = 0; i < 10; i++) {
        tempData[i] = [];
      }

      Object.values(resultLatest).forEach((prizes: any) => {
        // Loop through each prize
        prizes.forEach((prize: any) => {
          const valueStr = prize.value.toString().padStart(2, "0"); // Ensure value is at least 2 characters long
          const lastTwoDigits = valueStr?.slice(-2); // Get the last two digits
          const units = parseInt(lastTwoDigits[1], 10); // Get the units digit
          const tens = parseInt(lastTwoDigits[0], 10); // Get the tens digit

          // Add the units digit to the appropriate tens key in tempData
          tempData[tens].push(units);
        });
      });

      setNumsResult(tempData);
    }
  }, [resultLatest]);

  function formatArray(arr: any) {
    const values = arr.map((item: any) => item?.value);
    if (values.length > 1) {
      return values.join(" - ");
    } else if (values.length === 1) {
      return values[0];
    } else {
      return "";
    }
  }

  const generateNumbers = (match: any) => {
    const { donvi, chuc, tram, ngan } = valueSelect;
    let numbers = [];

    if (match === "match_2") {
      // Create numbers from chuc and donvi
      for (let t of chuc) {
        for (let u of donvi) {
          numbers.push(`${t}${u}`);
        }
      }
    } else if (match === "match_3") {
      // Create numbers from tram, chuc, and donvi
      for (let h of tram) {
        for (let t of chuc) {
          for (let u of donvi) {
            numbers.push(`${h}${t}${u}`);
          }
        }
      }
    } else if (match === "match_4") {
      // Create numbers from ngan, tram, chuc, and donvi
      for (let th of ngan) {
        for (let h of tram) {
          for (let t of chuc) {
            for (let u of donvi) {
              numbers.push(`${th}${h}${t}${u}`);
            }
          }
        }
      }
    }

    return numbers;
  };

  function transformString(input: string) {
    // Convert the string to lowercase
    const lowerCase = input?.toLowerCase();

    // Replace spaces with hyphens
    const hyphenated = lowerCase?.replace(/\s+/g, "-");

    // Remove accents and special characters
    const normalized = hyphenated
      ?.normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");

    return normalized;
  }

  useEffect(() => {
    const value = generateNumbers(match);
    setBetValue(value);
  }, [match, valueSelect]);

  const numPer = resultLatest?.["Đặc biệt"]?.[0]?.value
    ?.toString()
    .split("")
    .map(Number);

  useEffect(() => {
    setSelectedType(typeMatch[match][0]);
  }, [match]);

  const handleSubmit = async () => {
    try {
      if (!betValue?.length) return message.error("Vui lòng chọn cược");
      if (!value) return message.error("Bạn chưa nhập tiền cược");
      setLoading(true);
      const res = await requestService.post("/profile/postBetLottery", {
        data: {
          value: value * getRatePoint(),
          bet_id: selectedStation?.id,
          bet_value: betValue,
          transaction_type: selectedType?.type,
          bet_type: "lottery",
          bet_name: id || "",
        },
      });

      if (res && res.data) {
        message.success("Bạn đã cược thành công");
        await getHistories();
        await getUser();
      }
    } catch (error: any) {
      message.error(error.response?.data?.message);
    }
    setLoading(false);
  };

  const getUser = async () => {
    try {
      const res = await requestService.get("/profile/me");
      if (res && res.data) {
        dispatch(onSetUser(res?.data?.data));
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };
  const getHistories = async () => {
    try {
      const res = await requestService.get("/profile/history-transaction");
      if (res && res.data) {
        setHistories(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getHistories();
  }, []);

  const getOpen = (id: any) => {
    const data = open1.find((i) => i.id === id);
    return data;
  };
  const namebyType = {
    "2_so_dau_giai_dac_biet": "2 số đầu giải đặc biệt",
    "2_cang_dac_biet": "2 số cuối giải đặc biệt",
    "2_cang_bao_lo": "2 số đuôi các giải",
    "3_so_dau_giai_dac_biet": "3 số đầu giải đặc biệt",
    "3_cang_dac_biet": "3 số cuối giải đặc biệt",
    "3_cang_bao_lo": "3 số đuôi các giải",
  } as any;

  const historiesPending =
    histories?.length > 0
      ? histories.filter((i: any) => i?.transaction_status === "pending")
      : [];

  const historiesFinish =
    histories?.length > 0
      ? histories.filter((i: any) => i?.transaction_status !== "pending")
      : [];

  const totalBetPending = historiesPending.reduce(
    (total: any, i: any) => total + i?.value * (i?.bet_value?.length || 0),
    0
  );

  return (
    <div
      id="container"
      className={clsx({
        "!min-w-[100%]": breakpoint === "mobile",
      })}
    >
      <Spin fullscreen spinning={loading} />

      <div className="pdT-20 pdB-50">
        <div className="container">
          <div className="row">
            <div className="col-sm-9">
              <div className="result__top">
                <div className="tbl__time">
                  <div
                    className={clsx("tbl__time--inner ", {
                      "flex-col !p-2": breakpoint === "mobile",
                    })}
                  >
                    <div
                      className={clsx("time__title ", {
                        "!w-full": breakpoint === "mobile",
                      })}
                      id="time__title"
                    >
                      <p
                        id="typeGame"
                        className="fs-16 font-500 d-flex txt-uper pdT-5"
                      />
                      <p>Xổ số {selectedStation?.name}</p>
                      {/* <p />
                      <p
                        id="lotteryTxt"
                        className={clsx(
                          "colorPOrange fs-18 font-500 mgT-5 d-flex",
                          {
                            "text-center justify-center ":
                              breakpoint === "mobile",
                          }
                        )}
                      >
                        Bao Lô{" "}
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m8.25 4.5 7.5 7.5-7.5 7.5"
                            />
                          </svg>
                        </span>{" "}
                        Lô 2 Số
                      </p> */}
                    </div>
                    <div
                      className={clsx("time__inner", {
                        "!w-full": breakpoint === "mobile",
                      })}
                    >
                      <p className="time__location">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                          </svg>
                        </span>
                        Thời gian đặt cược
                      </p>
                      <p className="bout__txt">
                        Lượt xổ{" "}
                        <span id="open__issue" className="colorPOrange">
                          {new Date().toLocaleDateString("vi")}
                        </span>
                      </p>

                      {id && (
                        <Countdown
                          date={calculateTimeUntilHour(
                            id === "xsmn" ? 16 : id === "xsmb" ? 18 : 17
                          )}
                          renderer={(props) => {
                            if (props.total <= 0) {
                              return (
                                <div className="time__count timeCountBet">
                                  Đang chờ xổ số
                                </div>
                              );
                            }
                            return (
                              <div className="time__count timeCountBet">
                                <span>
                                  <b>{props.hours}</b>
                                </span>
                                :
                                <span>
                                  <b>{props.minutes}</b>
                                </span>
                                :
                                <span>
                                  <b>{props.seconds}</b>
                                </span>
                              </div>
                            );
                          }}
                        />
                      )}
                    </div>
                    <div
                      className={clsx("result__issue", {
                        "!w-full": breakpoint === "mobile",
                      })}
                    >
                      <p className="result__title">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z"
                            />
                          </svg>
                        </span>
                        <span id="waitPrize">Kết quả lượt xổ trước</span>
                      </p>
                      {/* <p className="issueBefore issue__before">
                        <span id="issueWait">
                          {new Date().toLocaleDateString()}
                        </span>
                      </p> */}
                      <div id="getPrizeDB" className="numDB">
                        {numPer?.map((n: any) => (
                          <span>{n}</span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="place__bet">
                <div id="frmBet">
                  <input
                    type="hidden"
                    name="lotteryid"
                    id="lotteryid"
                    defaultValue={105}
                  />
                  <input
                    type="hidden"
                    name="flag"
                    id="flag"
                    defaultValue="save"
                  />
                  <input
                    type="hidden"
                    name="lotteryname"
                    id="lotteryname"
                    defaultValue="Lottery VN"
                  />
                  <input type="hidden" name="lt_sel_modes" defaultValue={1} />
                  <input type="hidden" name="lt_sel_times" defaultValue={1} />
                  <div className="place__bet--inner box__shadowbg">
                    <div className="place__bet--lottery !px-3 md:px-[30px]">
                      <div className="nav__bet">
                        <ul className="clearfix" id="nav__game">
                          {openPrize?.map((i: any) => (
                            <li
                              className={clsx({
                                active: selectedStation?.id === i?.id,
                              })}
                            >
                              <a
                                href="javascript:void(0)"
                                onClick={() => setSelectedStation(i)}
                              >
                                Xổ số {i?.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div
                        className={clsx("top__sub", {
                          "!flex-col": breakpoint === "mobile",
                        })}
                      >
                        <div className="sub__nav pull-left">
                          <ul className="clearfix" id="sub__navgame">
                            <li className="" value={0}>
                              <a
                                onClick={() => setMatch("match_2")}
                                href="javascript:void(0)"
                                className={clsx({
                                  active: match === "match_2",
                                })}
                              >
                                2 Số
                              </a>
                            </li>
                            {/* <li value={1}>
                              <a href="javascript:void(0)">Lô 2 Số 1K</a>
                            </li> */}
                            <li value={2}>
                              <a
                                className={clsx({
                                  active: match === "match_3",
                                })}
                                onClick={() => setMatch("match_3")}
                                href="javascript:void(0)"
                              >
                                3 Số
                              </a>
                            </li>
                            {/* <li value={3}>
                              <a href="javascript:void(0)">Lô 4 Số</a>
                            </li> */}
                          </ul>
                        </div>
                        {/* <div className="tab__options pull-right ">
                          <ul className="clearfix" id="selectTabFastNum">
                            <li>
                              <a className="active" data-tab="digital">
                                <span>Chọn Số</span>
                              </a>
                            </li>
                            <li>
                              <a data-tab="input-digital">
                                <span>Nhập Số</span>
                              </a>
                            </li>
                            <li>
                              <a data-tab="f-digital">
                                <span>Chọn số nhanh</span>
                              </a>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                      <div className="nav__bet mb-4">
                        <ul className="clearfix" id="nav__game">
                          {typeMatch[match]?.map((i: any) => (
                            <li
                              className={clsx({
                                active: selectedType?.type === i?.type,
                              })}
                              style={{
                                float: "left",
                              }}
                            >
                              <a
                                href="javascript:void(0)"
                                onClick={() => setSelectedType(i)}
                              >
                                {i?.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div
                        className={clsx("clearfix mgB-15", {
                          "!flex-col": breakpoint === "mobile",
                        })}
                      >
                        <div
                          className={clsx("place__bet--exam", {
                            "!w-full": breakpoint === "mobile",
                          })}
                          id="bet__exam"
                        >
                          <div className="inner">
                            <p>
                              + Cược 2 số, trúng1 ăn 70 lần
                              <br />+ Cược 3 số, trúng 1 ăn 600 lần
                            </p>
                            {/* <p>
                              <span className="icon-help" /> Chỉ cần thanh toán
                              1,000 VND cho mỗi điểm đánh, tiền thắng cho mỗi
                              điểm là{" "}
                              {formatNumber(
                                1000 * (match === "match_2" ? 70 : 600)
                              )}{" "}
                              VND. Trong trường hợp thắng từ 2 lô trở lên thì số
                              tiền thắng sẽ được nhân lên tương ứng bấy nhiêu
                              lần.
                            </p> */}
                          </div>
                        </div>
                        <div id="ratio__lottery">
                          <div className="ratio__num">
                            <span className="ratioTitle">Tỉ lệ cược</span>{" "}
                            <span className="ratioTxt">1</span> <span>ăn</span>
                            <span id="valRatio" className="ratioTxt">
                              {match === "match_2" ? 70 : 600}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="num__bet">
                        <div className="unit__number" id="lottery__selector">
                          {match === "match_3" && (
                            <div className="unit__items">
                              <div
                                className={clsx("unit__items__wrapper", {
                                  "flex-col gap-4": breakpoint === "mobile",
                                })}
                              >
                                <div className="unit__item mb-5">
                                  <div className="unit__item__num flex flex-wrap gap-2">
                                    <span className="unitSlectNum">Trăm</span>
                                    {numbers.map((num) => (
                                      <div
                                        key={num}
                                        className="name"
                                        onClick={() => onChange("tram", num)}
                                      >
                                        <a
                                          href="javascript:void(0)"
                                          className={clsx({
                                            active:
                                              valueSelect["tram"].includes(num),
                                          })}
                                        >
                                          <span>{num}</span>
                                        </a>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="unit__items">
                            <div
                              className={clsx("unit__items__wrapper", {
                                "flex-col gap-4": breakpoint === "mobile",
                              })}
                            >
                              <div className="unit__item mb-5">
                                <div className="unit__item__num flex flex-wrap gap-2">
                                  <span className="unitSlectNum">Chục</span>
                                  {numbers.map((num) => (
                                    <div
                                      key={num}
                                      className="name"
                                      data-active={valueSelect["chuc"].includes(
                                        num
                                      )}
                                      onClick={() => onChange("chuc", num)}
                                    >
                                      <a
                                        href="javascript:void(0)"
                                        className={clsx({
                                          active:
                                            valueSelect["chuc"].includes(num),
                                        })}
                                      >
                                        <span>{num}</span>
                                      </a>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              {/* <div className="options__unit">
                                <a href="">
                                  <span>Tất cả</span>
                                </a>
                                <a href="">
                                  <span>Tài</span>
                                </a>
                                <a href="">
                                  <span>Xỉu</span>
                                </a>
                                <a href="">
                                  <span>Lẻ</span>
                                </a>
                                <a href="">
                                  <span>Chẵn</span>
                                </a>
                                <a href="">
                                  <span>Xóa</span>
                                </a>
                              </div> */}
                            </div>
                          </div>
                          <div className="unit__items">
                            <div
                              className={clsx("unit__items__wrapper", {
                                "flex-col gap-4": breakpoint === "mobile",
                              })}
                            >
                              <div className="unit__item mb-5">
                                <div className="unit__item__num flex flex-wrap gap-2">
                                  <span className="unitSlectNum">Đơn vị</span>
                                  {numbers.map((num) => (
                                    <div
                                      key={num}
                                      className="name"
                                      data-active={valueSelect[
                                        "donvi"
                                      ].includes(num)}
                                      onClick={() => onChange("donvi", num)}
                                    >
                                      <a
                                        href="javascript:void(0)"
                                        className={clsx({
                                          active:
                                            valueSelect["donvi"].includes(num),
                                        })}
                                      >
                                        <span>{num}</span>
                                      </a>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={clsx("ratio__odds", {
                          "flex-col gap-4": breakpoint === "mobile",
                        })}
                      >
                        <div
                          className="frm__item d-ib ver-c mgR-10 hide"
                          id="buy__continuously"
                        >
                          <input
                            defaultValue="2024-08-10"
                            name="lt_issue_start"
                            type="hidden"
                          />
                        </div>
                        <div className="num__multiple">
                          <div className="point d-ib ver-c" id="num__multiple">
                            <span className="d-ib-ver-c mgR-15 font-500">
                              Cấp Số Nhân:
                            </span>
                            <a
                              className="num__down"
                              onClick={() => {
                                if (value * 18000 * betValue?.length <= 0)
                                  return;
                                setValue((prev) => prev - 1);
                              }}
                            >
                              -
                            </a>
                            <input
                              type="number"
                              value={value}
                              onChange={(e) => setValue(+e.target.value)}
                            />
                            <a
                              className="num__up"
                              onClick={() => {
                                if (
                                  value * 18000 * betValue?.length >
                                  user?.real_balance
                                )
                                  return;
                                setValue((prev) => prev + 1);
                              }}
                            >
                              +
                            </a>
                          </div>
                        </div>
                        <div className="numSlectedCount">
                          <p className="font-500">
                            Đã chọn:
                            <span
                              className="color-red mx-2"
                              id="count__number--combination"
                            >
                              {betValue?.length || 0}
                            </span>
                            số
                          </p>
                        </div>
                        <div className="totalMoneySlected">
                          <p className="font-500 ">
                            Tiền đánh:
                            <span
                              className="color-red mx-2"
                              id="amount__total "
                            >
                              {formatNumber(
                                value * getRatePoint() * betValue?.length
                              )}
                            </span>
                            VNĐ
                          </p>
                        </div>
                        <input
                          type="hidden"
                          name="lt_total_nums"
                          id="lt_total_nums"
                          defaultValue={0}
                        />
                        <input
                          type="hidden"
                          name="lt_total_money"
                          id="lt_total_money"
                          defaultValue={0}
                        />
                      </div>
                      <div
                        className={clsx("btn__groupbet", {
                          "flex flex-col gap-2": breakpoint === "mobile",
                        })}
                      >
                        <button
                          id="bet__reset"
                          className=""
                          onClick={() => {
                            setBetValue([]);
                            setvalueSelect({
                              donvi: [],
                              chuc: [],
                              tram: [],
                            });
                          }}
                        >
                          <span className="inline-block">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-6 mr-3"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                              />
                            </svg>
                          </span>
                          Đặt lại
                        </button>
                        <button id="bet__now" onClick={handleSubmit}>
                          <span className="inline-block">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-6 mr-3"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z"
                              />
                            </svg>
                          </span>
                          Cược
                        </button>
                        {/* <button
                          className="btn__add--ticket mr-[8px] md:mr-0"
                          id="btnAddTicket"
                        >
                          <span className="inline-block">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-6 mr-3"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                              />
                            </svg>
                          </span>
                          Thêm cược
                        </button> */}
                      </div>
                    </div>
                    <div className="panel__tbl !px-3 !md:px-[30px]">
                      <div className="panel__tbl--top">
                        <div className="panel__title">Nội dung cược</div>
                      </div>
                      <div
                        className="tbl__pet--detail tbl__scroll"
                        id="tbl__betreview"
                      >
                        <div className="tbl__header">
                          <div className="tbl">
                            <div className="tbl__row">
                              <div className="w-7 tbl__col">STT</div>
                              <div className="w-16 tbl__col">Tên cược</div>
                              {breakpoint === "mobile" ? (
                                <div className="w-20 tbl__col">
                                  Thông tin cược
                                </div>
                              ) : (
                                <>
                                  <div className="w-20 tbl__col">Số cược</div>
                                  <div className="w-15 tbl__col">Tổng cược</div>

                                  <div className="w-15 tbl__col">Tiền cược</div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="grid__tbl">
                          <div className="gid__tbl--inner">
                            <div className="tbl">
                              {/* <div className="tbl__empty tbl__row">
                                <div className="tbl__col">
                                
                                </div>
                              </div> */}
                              {historiesPending &&
                                historiesPending?.map(
                                  (i: any, index: number) => (
                                    <div className="tbl__row">
                                      <div className="w-7 tbl__col">
                                        {index}
                                      </div>
                                      <div className="w-16 tbl__col">
                                        {namebyType[i?.transaction_type]}
                                      </div>
                                      {breakpoint === "mobile" ? (
                                        <div className="w-20 tbl__col">
                                          <div className="mb-3">
                                            {i?.bet_value?.join(",")}
                                          </div>
                                          <div className="mb-3">
                                            {i?.bet_value?.length} điểm
                                          </div>
                                          <div className="mb-3">
                                            =
                                            {formatNumber(
                                              i?.value * i?.bet_value?.length
                                            )}
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          <div className="w-20 tbl__col">
                                            {i?.bet_value?.join(",")}
                                          </div>
                                          <div className="w-15 tbl__col">
                                            {i?.bet_value?.length}
                                          </div>

                                          <div className="w-15 tbl__col">
                                            {formatNumber(
                                              i?.value * i?.bet_value?.length
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  )
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="panel__tbl--bottom clearfix">
                        <p className="pull-left fs-16 lh-50">
                          Tổng tiền cược{" "}
                          <span
                            className="color-red d-ib mgL-5"
                            id="total_moneyBet"
                          >
                            {formatNumber(totalBetPending)}{" "}
                          </span>
                          VNĐ
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="tbl">
                <div className="tbl__row">
                  <div className="tbl__result tbl__col">
                    <div
                      className="tbl__result--inner"
                      style={{ position: "relative" }}
                    >
                      <div id="aseanLotoLogo" className="asean-loto-logo">
                        <div className="logo-part">
                          <img src="/images/asean-lotto-logo.png" />
                        </div>
                        <div className="word-part">
                          <a
                            id="aseanResult"
                            className="word-part__result"
                            target="_blank"
                            href={`https://aseanlotto.com/live-results/${transformString(
                              selectedStation?.name
                            )}?lang=vi`}
                          >
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75"
                                />
                              </svg>
                            </span>
                            <span>Xem Kết Quả</span>
                          </a>
                          <a
                            id="aseanCollect"
                            className="word-part__collect no-border"
                            target="_blank"
                            href="https://aseanlotto.com/trending-research/d0c1deeffee74cc1b4bc476170560e95"
                          >
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z"
                                />
                              </svg>
                            </span>
                            <span>Thống Kê Cược</span>
                          </a>
                        </div>
                      </div>
                      <div className="box__shadowbg" id="partResultMega">
                        <div className="result__dai">
                          <table id="lotteryResultMega" className="result-mega">
                            <tbody id="lotteryResultMegaBody" />
                          </table>
                          <table>
                            <tbody>
                              <tr>
                                <td colSpan={2} className="text-center">
                                  Kết quả xổ số trước
                                </td>
                              </tr>
                              {resultLatest &&
                                Object.keys(resultLatest)?.map((title) => (
                                  <tr>
                                    <td>{title}</td>
                                    <td>
                                      {/* 830<span data-active="false">60</span> */}
                                      <span className="!text-[#333]">
                                        {formatArray(resultLatest[title])}
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                        <div id="firstLast" className="result__dauduoi">
                          <table>
                            <tbody>
                              <tr className="result__title">
                                <td>Đầu</td>
                                <td>Đuôi</td>
                              </tr>
                              {numsRessult &&
                                Object.keys(numsRessult).map((i) => (
                                  <tr>
                                    <td className="first__num">
                                      <span>{i}</span>
                                    </td>
                                    <td className="last__num">
                                      {numsRessult[i].join(",")}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="place__bet bet__history box__shadowbg">
            <div className="panel__tbl">
              <div className="panel__tbl--top">
                <div className="panel__title">Lịch sử cược</div>
                <div className="panel__options">
                  <a id="reloadHistory">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                        />
                      </svg>
                    </span>
                    Làm mới
                  </a>
                </div>
              </div>
              <div className="history__detail tbl__scroll" id="history__detail">
                <div className="tbl__header">
                  <div className="tbl">
                    <div className="tbl__row">
                      <div className="tbl__col w-9">Lượt xổ</div>
                      <div className="tbl__col w-10">Xổ số</div>
                      <div className="tbl__col w-10">Kiểu cược</div>
                      <div className="tbl__col w-14">Thời gian mua</div>
                      <div className="tbl__col w-14">Số cược</div>
                      <div className="tbl__col w-8">Tổng cược</div>
                      <div className="tbl__col w-12">Cấp số nhân</div>
                      <div className="tbl__col w-12">Tiền cược</div>
                      <div className="tbl__col w-11">Trạng thái</div>
                    </div>
                  </div>
                </div>
                <div className="grid__tbl" style={{ position: "relative" }}>
                  <div className="gid__tbl--inner">
                    <div className="tbl" id="history__bet--record">
                      {historiesFinish?.map((i: any) => (
                        <div className="tbl__empty tbl__row">
                          <div className="tbl__col w-10">
                            {getOpen(+i?.bet_id)?.name}
                          </div>
                          <div className="tbl__col w-10">
                            {namebyType[i?.transaction_type]}
                          </div>
                          <div className="tbl__col w-14">
                            {new Date(i?.createdAt)?.toLocaleDateString()}
                          </div>
                          <div className="tbl__col w-14">
                            {i?.bet_value?.join(",")}
                          </div>
                          <div className="tbl__col w-8">
                            {formatNumber(i?.value * i?.bet_value?.length)}
                          </div>

                          <div className="tbl__col w-12">
                            {formatNumber(i?.value)}
                          </div>
                          <div className="tbl__col w-11">
                            {i?.transaction_status === "pending" ? (
                              <Tag color="orange-inverse">Đang chờ</Tag>
                            ) : i?.transaction_status === "finish" ? (
                              <Tag color="green-inverse">Thắng</Tag>
                            ) : (
                              <Tag color="red-inverse">Thua</Tag>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lottery;
