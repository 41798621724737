import { Spin, message } from "antd";
import requestService from "api/request";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const ResetPassword = () => {
  const [form, setForm] = useState<any>();
  const [loadings, setLoading] = useState(false);

  const handleResetPassword = async (e: any) => {
    e.preventDefault()
    if (form?.newPass !== form?.confirmPass)
      return message.error("Xác nhận mật khẩu k khớp");
    setLoading(true);
    try {
      const res = await requestService.post("/profile/reset-password", {
        data: {
          ...form,
        },
      });
      if (res && res.data) {
        message.success("Reset success !!");
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Spin spinning={loadings} fullscreen />
      <div className="app1">
        <div className="header_profile">
          <div className="info_profile">
            <div></div>
            <div className="avatar_profile">
              <div className="name_profile"></div>

              <div
                style={{
                  marginLeft: "20px",
                  fontSize: "1.2rem",
                  fontWeight: 600,
                  color: "white",
                }}
              >
                Đổi mật khẩu
              </div>
            </div>
            <Link to="/login" className="icon_setting_header">
              &times;
            </Link>
          </div>
        </div>
        <div className="content_profile">
          <form
            style={{ display: "block", padding: "15px" }}
            onSubmit={handleResetPassword}
          >
            <div>
              <div>
                <input
                  className="ipadd"
                  type="password"
                  placeholder="Mật khẩu cũ"
                  value={form?.oldPass}
                  onChange={(e) =>
                    setForm({ ...form, oldPass: e.target.value })
                  }
                />
              </div>
              <div>
                {" "}
                <input
                  className="ipadd"
                  type="password"
                  placeholder="Mật khẩu mới"
                  value={form?.newPass}
                  onChange={(e) =>
                    setForm({ ...form, newPass: e.target.value })
                  }
                />
              </div>
              <div>
                {" "}
                <input
                  className="ipadd"
                  type="password"
                  placeholder="Nhập lại mật khẩu"
                  value={form?.confirmPass}
                  onChange={(e) =>
                    setForm({ ...form, confirmPass: e.target.value })
                  }
                />
              </div>
              <button type="submit" className="btn-submit">
                Xác nhận
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
