import { message } from "antd";
import requestService from "api/request";
import clsx from "clsx";
import { formatNumber } from "lib/helpers/format";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "store";

const Deposit = () => {
  const { appGlobal } = useAppSelector((state) => state.app) as any;
  const [form, setForm] = useState<any>({
    amount: 0,
    account_name: "",
    from: "",
    number_bank: "",
  });
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rndInt, setRndInt] = useState<any>(null);
  const [isTransfers, setIsTransfer] = useState(false);
  const [bankSelect, setbankSelect] = useState<any>();
  const generateRandom = () => {
    let newRndInt = Math.floor(Math.random() * 10000) + 1;
    if (newRndInt === rndInt) {
      newRndInt = Math.floor(Math.random() * 6) + 1; // Regenerate if same
    }
    setRndInt(newRndInt);
  };

  useEffect(() => {
    generateRandom();
  }, []);

  useEffect(() => {
    if (appGlobal) {
      setForm({
        ...form,
        name_bank: appGlobal?.BANK?.nameBank,
        account_name: appGlobal?.BANK?.accountName,
        number_bank: appGlobal?.BANK?.value,
      });
    }
  }, [appGlobal]);

  useEffect(() => {
    if (appGlobal?.BANK?.length > 0) setbankSelect(appGlobal?.BANK[0]);
  }, [appGlobal?.BANK]);

  const handleDesposit = async () => {
    if (+form?.amount < 200000)
      return message.warning("Vui lòng nhập số tiền nạp lớn hơn 200.000 !!!");
    setLoading(true);
    try {
      const res = await requestService.post("/profile/transferWithUser", {
        data: { ...form, isTransfers },
      });
      if (res && res.data) {
        message.success("Đã gửi thông tin nạp tiền");
        setActive(true);
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message)
    }
    setLoading(false);
  };

  // if (active)
  //   return (
  //     <div className="tab-content">
  //       <div className="tab-pane fade " id="inter-banking">
  //         <h2 className="pwo-body-title">Thực hiện lệnh chuyển khoản</h2>
  //         <div className="pwo-body-note">
  //           <p>
  //             - Truy cập vào website Internet Banking hoặc Mobile Banking của{" "}
  //             <b style={{ color: "blue" }}>
  //               Southeast Asia Commercial Joint Stock Bank (SeABank)
  //             </b>{" "}
  //             để thực hiện chuyển khoản.
  //           </p>
  //           <p>
  //             - Mã gửi tiền trong mục nội dung bên dưới (Chuyen Khoan CKD75VFY1)
  //             chỉ có hiệu lực trong vòng 20 phút kể từ khi được tạo.
  //           </p>
  //           <p>
  //             - Quý khách vui lòng <b style={{ color: "red" }}>Điền Đúng</b>{" "}
  //             thông tin bên dưới và{" "}
  //             <b style={{ color: "red" }}>
  //               chọn phướng thức chuyển khoản nhanh 24/7
  //             </b>{" "}
  //             để được cập nhật tiền ngay lập tức.
  //           </p>
  //           <p className="p-note">
  //             * Lưu ý: Quý khách vui lòng nhập đúng số tiền{" "}
  //             <b style={{ color: "red" }}>15.000.000 VND</b> và chọn hình thức
  //             phí chuyển{" "}
  //             <b style={{ color: "red" }}>'Người/Đơn vị chuyển trả phí'</b> để
  //             nhận được chính xác số tiền. Nếu không trùng khớp, lệnh của quý
  //             khách sẽ bị huỷ. Mọi thắc mắc xin vui lòng liên hệ bộ phận hỗ trợ
  //             khách hàng.
  //           </p>
  //         </div>
  //         <div className="pwo-body-syntax">
  //           <div id="pwo-syntax-desktop">
  //             <table className="table table-bordered">
  //               <tbody>
  //                 <tr>
  //                   <td>Ngân hàng</td>
  //                   <td className="pwo-bold" colSpan={2}>
  //                     Southeast Asia Commercial Joint Stock Bank (SeABank)
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td>Số tài khoản ủy nhiệm</td>
  //                   <td className="pwo-bold">
  //                     <input
  //                       id="pwo-bank-account-no"
  //                       type="text"
  //                       defaultValue={2912972969}
  //                       className="pwo-input"
  //                     />
  //                   </td>
  //                   <td style={{ width: 20 }}>
  //                     <h4>
  //                       <span id="pwos-no" className="label label-default">
  //                         Copy
  //                       </span>
  //                     </h4>
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td>Tên chủ tài khoản</td>
  //                   <td className="pwo-bold">
  //                     <input
  //                       id="pwo-bank-account-name"
  //                       type="text"
  //                       defaultValue="TRINH QUOC CHUONG"
  //                       className="pwo-input"
  //                     />
  //                   </td>
  //                   <td>
  //                     <h4>
  //                       <span id="pwos-name" className="label label-default">
  //                         Copy
  //                       </span>
  //                     </h4>
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td>Chi nhánh</td>
  //                   <td>HCM</td>
  //                   <td />
  //                 </tr>
  //                 <tr>
  //                   <td>Tổng số tiền nộp</td>
  //                   <td className="pwo-red pwo-bold">
  //                     15.000.000 VND
  //                     <input
  //                       id="pwo-amount"
  //                       type="text"
  //                       defaultValue={15000000}
  //                       style={{ color: "#fff" }}
  //                       className="pwo-input-amount"
  //                     />
  //                   </td>
  //                   <td>
  //                     <h4>
  //                       <span id="pwos-amount" className="label label-default">
  //                         Copy
  //                       </span>
  //                     </h4>
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td>Nội dung</td>
  //                   <td className="pwo-bold">
  //                     <input
  //                       id="pwo-bank-note"
  //                       type="text"
  //                       defaultValue="Chuyen Khoan CKD75VFY1"
  //                       className="pwo-input"
  //                     />
  //                   </td>
  //                   <td>
  //                     <h4>
  //                       <span id="pwos-note" className="label label-default">
  //                         Copy
  //                       </span>
  //                     </h4>
  //                   </td>
  //                 </tr>
  //               </tbody>
  //             </table>
  //           </div>
  //           <div id="pwo-syntax-mobile">
  //             <table className="table">
  //               <tbody>
  //                 <tr>
  //                   <td colSpan={2}>
  //                     Southeast Asia Commercial Joint Stock Bank (SeABank)
  //                     <input
  //                       type="hidden"
  //                       id="pwo-bank-code"
  //                       name="pwo-bank-code"
  //                       defaultValue="SEA"
  //                     />
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td style={{ width: "80%" }}>
  //                     Số tài khoản ủy nhiệm
  //                     <br />
  //                     <input
  //                       id="pwom-bank-account-no"
  //                       type="text"
  //                       defaultValue={2912972969}
  //                       className="pwom-input"
  //                     />
  //                   </td>
  //                   <td className="pwom-td" style={{ width: "20%" }}>
  //                     <h4>
  //                       <span id="pwosm-no" className="label label-default">
  //                         Copy
  //                       </span>
  //                     </h4>
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     Tên chủ tài khoản
  //                     <br />
  //                     <input
  //                       id="pwom-bank-account-name"
  //                       type="text"
  //                       defaultValue="TRINH QUOC CHUONG"
  //                       className="pwom-input"
  //                     />
  //                   </td>
  //                   <td className="pwom-td">
  //                     <h4>
  //                       <span id="pwosm-name" className="label label-default">
  //                         Copy
  //                       </span>
  //                     </h4>
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     Chi nhánh
  //                     <br />
  //                     HCM
  //                   </td>
  //                   <td />
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     Tổng số tiền nộp
  //                     <br />
  //                     <span className="pwo-red pwo-bold">15.000.000 VND</span>
  //                     <input
  //                       id="pwom-amount"
  //                       type="text"
  //                       defaultValue={15000000}
  //                       style={{ color: "#fff" }}
  //                       className="pwo-input-amount"
  //                     />
  //                   </td>
  //                   <td className="pwom-td">
  //                     <h4>
  //                       <span id="pwosm-amount" className="label label-default">
  //                         Copy
  //                       </span>
  //                     </h4>
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     Nội dung
  //                     <br />
  //                     <input
  //                       id="pwom-bank-note"
  //                       type="text"
  //                       defaultValue="Chuyen Khoan CKD75VFY1"
  //                       className="pwom-input"
  //                     />
  //                   </td>
  //                   <td className="pwom-td">
  //                     <h4>
  //                       <span id="pwosm-note" className="label label-default">
  //                         Copy
  //                       </span>
  //                     </h4>
  //                   </td>
  //                 </tr>
  //               </tbody>
  //             </table>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="tab-pane fade in active p-10" id="qr-code">
  //         <div className="pwo-qrcode-amount">
  //           {formatNumber(form?.amount)} <span>VND</span>
  //         </div>
  //         <div className="pwo-qrcode-image" id="div_image_qr_code">
  //           <img src={appGlobal?.BANK?.urlImage} className="mb-4 w-[200px]" />
  //           <div className="flex gap-4 items-center text-blue-600 mb-4">
  //             <div>Tên tài khoản</div>
  //             <div>{appGlobal?.BANK?.nameBank}</div>
  //           </div>
  //           <div className="flex gap-4 items-center text-blue-600 mb-4">
  //             <div>Số tài khoản</div>
  //             <div>{appGlobal?.BANK?.value}</div>
  //           </div>
  //         </div>
  //         <div className="pwo-qrcode-content">
  //           Nội dung bắt buộc:
  //           <input
  //             type="text"
  //             defaultValue={`Chuyen Khoan ${rndInt}`}
  //             id="pwoqr-code"
  //             style={{
  //               width: "100%",
  //               fontSize: 23,
  //               textAlign: "center",
  //               marginBottom: 10,
  //             }}
  //           />
  //         </div>
  //         <div className="form-group row justify-center">
  //           <div className="col-md-3 col-sm-4 col-xs-6 mx-auto">
  //             <button
  //               type="submit"
  //               className="btn btn-success mt-5 p-2 btn-block btn-custom font-weight-bolder"
  //               onClick={() => {
  //                 setActive(false);
  //                 setForm({ ...form, amount: 0, from: "" });
  //               }}
  //             >
  //               Hoàn thành
  //             </button>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );

  return (
    <div className="container">
      <div className="border-0 mt-3">
        <div className="">
          <hr className="" />
          {!isTransfers && (
            <div className="" id="bank-items">
              <div className="rowcha row" id="bankList">
                {appGlobal?.BANK?.map((i: any) => (
                  <div className="mx-2">
                    <div
                      className="shadow-sm card my-2 card-custom not"
                      onClick={() => setbankSelect(i)}
                    >
                      {" "}
                      <div className="card-body p-0 text-center">
                        <>
                          <img src={i?.icon} alt="SEA" className="img-fluid" />
                          <img
                            src="/images/lx88/common/tick.png"
                            alt="tick"
                            className="tick"
                          />
                        </>
                      </div>
                    </div>
                  </div>
                ))}{" "}
              </div>
              <div className="tab-content">
                <div className="tab-pane fade in active p-10" id="qr-code">
                  {/* <div className="pwo-qrcode-amount">
            {formatNumber(form?.amount)} <span>VND</span>
          </div> */}
                  <div className="pwo-qrcode-image" id="div_image_qr_code">
                    <img
                      src={bankSelect?.urlImage}
                      className="mb-4 w-[200px]"
                    />
                    <div className="flex gap-4 items-center text-blue-600 mb-4">
                      <div>Tên tài khoản</div>
                      <div>{bankSelect?.nameBank}</div>
                    </div>
                    <div className="flex gap-4 items-center text-blue-600 mb-4">
                      <div>Số tài khoản</div>
                      <div>{bankSelect?.value}</div>
                    </div>
                  </div>
                  <div className="pwo-qrcode-content">
                    Nội dung bắt buộc: Username người nhận tiền (Ví dụ:
                    xoso_123)
                  </div>
                  <div className="form-group row justify-center">
                    <div className="col-md-3 col-sm-4 col-xs-6 mx-auto">
                      <button
                        type="submit"
                        className="btn btn-success mt-5 p-2 btn-block btn-custom font-weight-bolder"
                        onClick={() => {
                          setActive(false);
                          setForm({ ...form, amount: 0, from: "" });
                        }}
                      >
                        Hoàn thành
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <ul className="tabs clearfix my-6">
            <li
              className={clsx({
                active: !isTransfers,
              })}
              onClick={() => setIsTransfer(false)}
            >
              <a>Nạp tiền</a>
            </li>

            <li
              className={clsx({
                active: isTransfers,
              })}
              onClick={() => setIsTransfer(true)}
            >
              <a>Chuyển khoản</a>
            </li>
          </ul>

          {isTransfers && (
            <div className="form-group row mt-5 custom-form justify-content-center ">
              <div className="col-md-12 text-left">
                <label
                  htmlFor="amount"
                  className="col-form-label font-italic notes"
                >
                  {" "}
                  <b>Nhập username của người nhận tiền</b>
                </label>
              </div>
              <div className="col-md-12">
                <input
                  type="text"
                  className="form-control custom-field currency"
                  id="amount"
                  value={form?.user_name}
                  onChange={(e) => {
                    setForm({ ...form, user_name: e.target.value });
                  }}
                />
              </div>
              <div className="form-group row mt-5 custom-form justify-content-center ">
                <div className="col-md-12 text-left">
                  <label
                    htmlFor="amount"
                    className="col-form-label font-italic notes !text-left"
                  >
                    {" "}
                    <b>Số tiền chuyển</b>
                    {/* (Tối thiểu{" "}
                <span className="highlight ">200,000</span> / Tối đa{" "}
                <span className="highlight">100,000,000</span> VND) */}
                  </label>
                </div>
                <div className="col-md-5">
                  <input
                    type="tel"
                    className="form-control custom-field currency"
                    id="amount"
                    value={form?.amount}
                    onChange={(e) => {
                      setForm({ ...form, amount: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="row justify-content-center text-center notes ">
                <div className="btn-all col-md-12">
                  <button
                    className="btn btnAmtSelect btn-sm mt-2"
                    onClick={() => setForm({ ...form, amount: 200000 })}
                  >
                    200K
                  </button>
                  <button
                    className="btn btnAmtSelect btn-sm mt-2"
                    onClick={() => setForm({ ...form, amount: 500000 })}
                  >
                    500K
                  </button>
                  <button
                    className="btn btnAmtSelect btn-sm mt-2"
                    onClick={() => setForm({ ...form, amount: 1000000 })}
                  >
                    1,000K
                  </button>
                  <button
                    className="btn btnAmtSelect btn-sm mt-2"
                    onClick={() => setForm({ ...form, amount: 2000000 })}
                  >
                    2,000K
                  </button>
                  <button
                    className="btn btnAmtSelect btn-sm mt-2"
                    onClick={() => setForm({ ...form, amount: 5000000 })}
                  >
                    5,000K
                  </button>
                  <button
                    className="btn btnAmtSelect btn-sm mt-2"
                    onClick={() => setForm({ ...form, amount: 10000000 })}
                  >
                    10,000K
                  </button>
                  <button
                    className="btn btnAmtSelect btn-sm mt-2"
                    onClick={() => setForm({ ...form, amount: 15000000 })}
                  >
                    15,000K
                  </button>
                  <button
                    className="btn btnAmtSelect btn-sm mt-2"
                    onClick={() => setForm({ ...form, amount: 20000000 })}
                  >
                    20,000K
                  </button>
                  <button
                    className="btn btnAmtSelect btn-sm mt-2"
                    onClick={() => setForm({ ...form, amount: 100000000 })}
                  >
                    100,000K
                  </button>
                </div>
              </div>
              <div className="form-group row justify-center">
                <div className="col-md-3 col-sm-4 col-xs-6 mx-auto">
                  <button
                    type="submit"
                    className="btn btn-success mt-5 p-2 btn-block btn-custom font-weight-bolder"
                    onClick={handleDesposit}
                  >
                    GỬI ĐI
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* <div className="form-group row justify-center">
            <div className="col-md-3 col-sm-4 col-xs-6 mx-auto">
              <button
                type="submit"
                className="btn btn-success mt-5 p-2 btn-block btn-custom font-weight-bolder"
                onClick={handleDesposit}
              >
                GỬI ĐI
              </button>
            </div>
          </div> */}
          <form action="/pwOffSend.htm" method="post" id="recharge">
            <input
              type="hidden"
              defaultValue="luongdat1996"
              name="lname"
              id="lname"
            />
            <input type="hidden" defaultValue="pw-off" id="pid" name="pid" />{" "}
            <input type="hidden" defaultValue={1} id="accid" name="accid" />{" "}
            <input
              type="hidden"
              defaultValue=""
              name="ebankcode"
              id="ebankcode"
            />{" "}
            <input type="hidden" defaultValue="" name="eamount" id="amt" />{" "}
            <input
              type="hidden"
              defaultValue="null"
              name="orderno"
              id="orderno"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Deposit;
