import React from 'react'
import { Link } from 'react-router-dom'

const AddBank = () => {
  return (
    <>
      <div className="app1">
        <div className="header_profile">
          <div className="info_profile">
            <div></div>
            <div className="avatar_profile">
              <div className="name_profile"></div>

              <div
                style={{
                  marginLeft: "20px",
                  fontSize: "1.2rem",
                  fontWeight: 600,
                  color: "white"
                }}>
                Thêm tài khoản ngân hàng
              </div>
            </div>
            <Link to="/mine" className="icon_setting_header">
              &times;
            </Link>
          </div>
        </div>
        <div className="content_profile">
          <div className="list-bank">
            <form
              style={{
                display: "block",
                padding: "15px",
                textAlign: "left"
              }}
            >
              <div>
                <div>
                  <label>Tên ngân hàng</label>
                  <input className="ipadd" type="text" placeholder="Nhập tên ngân hàng" />
                </div>
                <div>
                  {" "}
                  <label>Số tài khoản</label>
                  <input className="ipadd" type="number" placeholder="Nhập số tài khoản" />
                </div>
                <div>
                  {" "}
                  <label>Chủ tài khoản</label>
                  <input className="ipadd" type="text" placeholder="Nhập họ tên người nhận" />
                </div>
                <button type="submit" className="btn-submit">
                  Xác nhận
                </button>
              </div>
            </form>
          </div>
        </div>


      </div>
    </>
  )
}

export default AddBank