import { message, Spin } from "antd";
import requestService from "api/request";
import clsx from "clsx";
import useBreakpoint from "hooks/useBreakpoint";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { onSetUser } from "store/app";

const Bank = () => {
  const breakpoint = useBreakpoint();
  const { user } = useAppSelector((state) => state.app) as any;
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [form, setForm] = useState<any>();

  function formatNumber(number: any) {
    const numberStr = number?.toString();
    const lastThreeDigits = numberStr?.slice(-3);
    const formatted = `***${lastThreeDigits}`;
    return formatted;
  }

  const handleSubmit = async () => {
    if (!form?.name_bank || !form?.account_name || !form?.number_bank)
      return message.warning("Vui lòng điền đầy đủ thông tin");

    setLoading(true);
    try {
      const res = await requestService.post("/profile/update", {
        data: {
          ...form,
        },
      });

      if (res && res.data) {
        message.success("Đã thêm tài khoản ngân hàng thành công");
        getUser();
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message);
    }
    setLoading(false);
  };

  const getUser = async () => {
    try {
      const res = await requestService.get("/profile/me");
      if (res && res.data) {
        dispatch(onSetUser(res?.data?.data));
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };
  return (
    <div className="tab-content box__shadowbg">
      <Spin fullscreen spinning={loading} />
      <div id="bankCard">
        {(!user?.name_bank || !user?.account_name || !user?.number_bank) && (
          <div id="addBank">
            <div id="bankBindingForm">
              <span id="bankBindingForm:inputMain">
                <div className="popup__content">
                  <div
                    className={clsx("frm__item ", {
                      "flex flex-col": breakpoint === "mobile",
                    })}
                  >
                    <label className="md:w-35 text-left pdR-15 font-500">
                      Tên ngân hàng:
                    </label>
                    <div className="slect__box md:w-45">
                      <select
                        id="bankBindingForm:bankNameSelect"
                        name="bankBindingForm:bankNameSelect"
                        className="w-100 h-[40px]"
                        size={1}
                        value={form?.name_bank}
                        onChange={(e) =>
                          setForm({ ...form, name_bank: e.target.value })
                        }
                      >
                        {" "}
                        <option value="Ngân Hàng Á Châu (ACB)">
                          Ngân Hàng Á Châu (ACB)
                        </option>
                        <option value="Agribank">
                          NNPT Nông thôn Việt Nam (Agribank)
                        </option>
                        <option value="BIDV">
                          Đầu tư và Phát triển Việt Nam (BidvBank)
                        </option>
                        <option value="Đông Á">Đông Á (DongA)</option>
                        <option value="EximBank">
                          Xuất Nhập Khẩu (EximBank)
                        </option>
                        <option value="Sacombank">
                          Sài Gòn Thương Tín (SacomBank)
                        </option>
                        <option value="Techcombank">
                          Kỹ Thương (TechcomBank)
                        </option>
                        <option value="Vietinbank">
                          Công thương Việt Nam (VietinBank)
                        </option>
                        <option value="Vietcombank">
                          Ngoại Thương Việt Nam (VietcomBank)
                        </option>
                        <option value="VIB">Quốc Tế (VibBank)</option>
                        <option value="VPBank">
                          Việt Nam Thịnh Vượng (VP Bank)
                        </option>
                        <option value="MaritimeBank">Hàng Hải (MSBBank)</option>
                        <option value="SHB">Sài Gòn – Hà Nội (SHB)</option>
                        <option value="ABBank">An Bình (ABBank)</option>
                        <option value="LienVietPostBank">
                          Bưu điện Liên Việt (LienVietPostBank)
                        </option>
                        <option value="Nam A Bank">Nam Á (NamABank)</option>
                        <option value="SeaBank">Đông Nam Á (SeABank)</option>
                        <option value="HSBC">HSBC Việt Nam (HSBC)</option>
                        <option value="HDB">
                          Phát triển TP Hồ Chí Minh (HD Bank)
                        </option>
                        <option value="MBBank">Quân Đội (MBBank)</option>
                        <option value="ShinHan Bank">Shinhan Việt Nam</option>
                        <option value="TPBank">Tiên Phong (TPBank)</option>
                        <option value="Vietbank">
                          Việt Nam Thương Tín (Vietbank)
                        </option>
                        <option value="BacA Bank">Bắc Á (BacABank)</option>
                        <option value="Ban Viet">Bản Việt (BanViet)</option>
                        <option value="Baoviet Bank">
                          Bảo Việt (BaovietBank)
                        </option>
                        <option value="PVComBank">
                          Đại Chúng Việt Nam (PVComBank)
                        </option>
                        <option value="OceanBank">Đại Dương (OceanBank)</option>
                        <option value="GPBank">
                          Dầu khí toàn cầu (GPBank)
                        </option>
                        <option value="Kienlongbank">
                          Kiên Long (Kienlongbank)
                        </option>
                        <option value="OCB">Phương Đông (OCB)</option>
                        <option value="NCB">Quốc dân (NCB)</option>
                        <option value="VietABank">Việt Á (VietABank)</option>
                        <option value="PGBank">
                          Xăng dầu Petrolimex (PGBank)
                        </option>
                        <option value="CAKE">Cake by VPBank</option>
                        <option value="WOORI">WOORI Bank</option>
                        <option value="LioBank">LioBank</option>
                        <option value="IVB">INDOVINABANK</option>
                        <option value="TIMO">BVBANK&nbsp;TIMO</option>
                        <option value="CIMB">
                          NGAN HANG TNHH MTV&nbsp;CIMB&nbsp;VIET NAM
                        </option>
                        <option value="CITI">CITIBANK VIETNAM</option>
                      </select>
                      <span className="icon-keyboard_arrow_down" />
                    </div>
                  </div>

                  <div
                    className={clsx("frm__item ", {
                      "flex flex-col": breakpoint === "mobile",
                    })}
                  >
                    <label className="md:w-35 text-left pdR-15 font-500 ver-t mgT-10 !text-left">
                      Họ Tên Chủ Tài Khoản NH:
                    </label>
                    <div className="iput w-full">
                      <input
                        value={form?.account_name}
                        onChange={(e) =>
                          setForm({ ...form, account_name: e.target.value })
                        }
                        type="text"
                        name="bankBindingForm:j_id50"
                        className="h-[40px] w-full form-control-name bank-input"
                        maxLength={30}
                      />
                      <p className="fs-13 mgT-5 color-red">
                        Họ và tên IN HOA và phải có khoảng cách. (Đây là ví dụ:
                        NGUYEN VAN B)
                      </p>
                    </div>
                  </div>
                  <div
                    className={clsx("frm__item ", {
                      "flex flex-col": breakpoint === "mobile",
                    })}
                  >
                    <label className="md:w-35 text-left pdR-15 font-500">
                      Số tài khoản:
                    </label>
                    <div className="iput w-full">
                      <input
                        value={form?.number_bank}
                        onChange={(e) =>
                          setForm({ ...form, number_bank: e.target.value })
                        }
                        type="text"
                        name="bankBindingForm:j_id52"
                        className="h-[40px] w-full number-account form-control-number bank-input"
                        maxLength={20}
                      />
                    </div>
                  </div>

                  <div className="frm__item frm__btn flex justify-center">
                    <button
                      type="button"
                      className="btn__primary pdT-10 pdB-10 mgR-10"
                      id="but"
                      onClick={handleSubmit}
                    >
                      <span className="icon-add fs-18 mgR-5 d-ib ver-c" />
                      Đăng Ký Tài Khoản Ngân Hàng
                    </button>
                  </div>
                  <div className="frm__item">
                    <p className="fs-13 mgT-5 color-red">
                      * Chú ý: Cập nhật Họ Tên và số Tài Khoản sai với quy định
                      sẽ KHÔNG thể Rút Tiền và Gửi Tiền
                    </p>
                  </div>
                </div>
              </span>
            </div>
          </div>
        )}

        <div className="tbl__record">
          <form
            id="bankCaradData"
            name="bankCaradData"
            method="post"
            action="/page/PersonBank.shtml"
            encType="application/x-www-form-urlencoded"
          >
            <input
              type="hidden"
              name="bankCaradData"
              defaultValue="bankCaradData"
            />
            <span id="bankCaradData:pgData">
              <div className="wrap__tbl">
                <table>
                  <tbody>
                    <tr className="">
                      <td className="w-40">
                        <span>Tên ngân hàng</span>
                      </td>
                      <td className="w-30">
                        <span>Số tài khoản</span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr className="">
                      <td className="w-40">
                        <span>{user?.name_bank || "-"}</span>
                      </td>
                      <td className="w-30">
                        <span>{formatNumber(user?.number_bank) || "-"}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </span>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Bank;
