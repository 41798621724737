import "./App.css";
import RenderRouter from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
import { ConfigProvider, Spin, theme as a } from "antd";
import { useAppDispatch, useAppSelector } from "store";
import requestService from "api/request";
import { onLoadingScreen, onSetAppGlobal, onSetUser } from "store/app";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
function App() {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.app);
  const { i18n } = useTranslation();

  const getAppConfigs = async () => {
    try {
      const res = await requestService.get('/guest/configs')
      if (res && res.data) {
        const result = res.data.data.reduce((acc: any, item: any) => {
          const { type, ...rest } = item;

          // Kiểm tra nếu đã có `type` trong `acc`
          if (acc[type]) {
            // Nếu đã tồn tại và không phải mảng, chuyển thành mảng chứa object cũ và object mới
            if (!Array.isArray(acc[type])) {
              acc[type] = [acc[type]];
            }
            // Thêm đối tượng mới vào mảng
            acc[type].push(rest);
          } else {
            // Nếu chưa tồn tại `type`, lưu như một object
            acc[type] = rest;
          }

          return acc;
        }, {});
        dispatch(onSetAppGlobal(result))
      }
    } catch (error) {
      console.log(error);

    }
  }
  const getUser = async () => {
    dispatch(onLoadingScreen(true));
    try {
      const res = await requestService.get("/profile/me");
      if (res && res.data) {
        dispatch(onSetUser(res?.data?.data));
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
      localStorage.clear();
    }
    dispatch(onLoadingScreen(false));
  };

  useEffect(() => {
    if (!user && localStorage.getItem("accessToken")) getUser();
    getAppConfigs()
  }, [user]);

  ;

  return (
    <ConfigProvider>
      <Router>
        <RenderRouter />
      </Router>
    </ConfigProvider>
  );
}

export default App;
