import { Spin, message, notification } from "antd";
import requestService from "api/request";
import clsx from "clsx";
import useBreakpoint from "hooks/useBreakpoint";
import {
  getJSONFromUrl,
  validateEmail,
  validatePassword,
} from "lib/helpers/format";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "store";

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [form, setForm] = useState<any>();
  const [loading, setLoading] = useState(false);
  const breakpoint = useBreakpoint()
  let { r } = getJSONFromUrl();
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const { appGlobal } = useAppSelector((state) => state.app) as any;

  const [showPassword0, setShowPassword0] = useState(false);

  const toggleShowPassword0 = () => {
    setShowPassword0(!showPassword0);
  };

  function generate(limit: number) {
    var digits = "0123456789";
    let OTP = "";
    for (let i = 0; i < limit; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    return OTP;
  }

  const setUserName = () => {
    const num = generate(3);
    setForm({ ...form, user_name: `xoso_${num}` });
  };






  const handleRegister = async (e: any) => {
    e.preventDefault();

    if (form?.password !== form?.confirm_password)
      return message.error("Xác nhận mật khẩu không đúng");

    // const { email, password } = form;
    // if (!validateEmail(email)) {
    //   return message.error("Invalid email format");
    // }
    // if (!validatePassword(password)) {
    //   return message.error("Password must be at least 6 characters");
    // }
    setLoading(true);
    try {
      const res = await requestService.post("/auth/register", {
        data: {
          ...form,
        },
      });
      if (res && res.data) {
        message.success(res?.data?.message);
      }
    } catch (error: any) {
      console.log(error);

      message.error(
        error?.response?.data?.message || "Có lỗi xảy ra thử lại !!!"
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    setForm({
      ...form,
      ref_code: r,
      user_name: `xoso${generate(3)}`
    });
  }, []);

  return (
    <div className="regPage">
      <Spin spinning={loading} fullscreen />
      <div id="container">
        {/* InstanceBeginEditable name="EditRegion2" */}
        <div className="register__page bg__full">
          <div className="inner">
            <div className="d-ib text-left">
              <div className="clearfix title">
                <h2>Đăng Ký Tài Khoản</h2>
              </div>
              <div className="frm__item">
                <label className="l-required">Tên tài khoản: </label>
                <div className="iput" id="username_error">
                  <input
                    type="text"
                    id="lname"
                    placeholder="Không được ít hơn 5 ký tự và nhiều hơn 30 ký tự"
                    value={form?.email}
                    onChange={(e) =>
                      setForm({ ...form, email: e.target.value })
                    }
                  />
                  <span className="error-note" />
                </div>
              </div>
              <div className="frm__item">
                <label className="l-required">Mật khẩu đăng nhập: </label>
                <div className="iput" id="password_error">
                  <input
                    type="password"
                    id="lpwd"
                    placeholder="Nhập mật khẩu đăng nhặp"
                    value={form?.password}
                    onChange={(e) =>
                      setForm({ ...form, password: e.target.value })
                    }
                  />
                  <span className="icon-visibility" />
                  <span className="error-note" />
                </div>
              </div>
              <div className="frm__item">
                <label className="l-required">Xác nhận mật khẩu: </label>
                <div className="iput" id="password_check_error">
                  <input
                    type="password"
                    id="checkpwd"
                    placeholder="Xác nhận mật khẩu"
                    value={form?.confirm_password}
                    onChange={(e) =>
                      setForm({ ...form, confirm_password: e.target.value })
                    }
                  />
                  <span className="icon-visibility" />
                  <span className="error-note" />
                </div>
              </div>
              <div className="frm__item">
                <label className="l-required">Mã tài khoản: </label>
                <div className="iput" id="nickname_error">
                  <input
                    disabled
                    type="text"
                    id="enickname"
                    placeholder="Biệt danh"
                    value={form?.user_name}
                  />
                  <span className="error-note" />
                </div>
              </div>

              <div className="frm__item">
                <label className="l-required">Tên ngân hàng: </label>
                <div className="iput" id="nickname_error">
                  <select
                    id="bankBindingForm:bankNameSelect"
                    name="bankBindingForm:bankNameSelect"
                    className="w-full md:w-[300px] h-[40px]"
                    size={1}
                    value={form?.name_bank}
                    onChange={(e) =>
                      setForm({ ...form, name_bank: e.target.value })
                    }
                  >
                    {" "}
                    <option value="">
                      Chọn ngân hàng
                    </option>
                    <option value="Ngân Hàng Á Châu (ACB)">
                      Ngân Hàng Á Châu (ACB)
                    </option>
                    <option value="Agribank">
                      NNPT Nông thôn Việt Nam (Agribank)
                    </option>
                    <option value="BIDV">
                      Đầu tư và Phát triển Việt Nam (BidvBank)
                    </option>
                    <option value="Đông Á">Đông Á (DongA)</option>
                    <option value="EximBank">
                      Xuất Nhập Khẩu (EximBank)
                    </option>
                    <option value="Sacombank">
                      Sài Gòn Thương Tín (SacomBank)
                    </option>
                    <option value="Techcombank">
                      Kỹ Thương (TechcomBank)
                    </option>
                    <option value="Vietinbank">
                      Công thương Việt Nam (VietinBank)
                    </option>
                    <option value="Vietcombank">
                      Ngoại Thương Việt Nam (VietcomBank)
                    </option>
                    <option value="VIB">Quốc Tế (VibBank)</option>
                    <option value="VPBank">
                      Việt Nam Thịnh Vượng (VP Bank)
                    </option>
                    <option value="MaritimeBank">Hàng Hải (MSBBank)</option>
                    <option value="SHB">Sài Gòn – Hà Nội (SHB)</option>
                    <option value="ABBank">An Bình (ABBank)</option>
                    <option value="LienVietPostBank">
                      Bưu điện Liên Việt (LienVietPostBank)
                    </option>
                    <option value="Nam A Bank">Nam Á (NamABank)</option>
                    <option value="SeaBank">Đông Nam Á (SeABank)</option>
                    <option value="HSBC">HSBC Việt Nam (HSBC)</option>
                    <option value="HDB">
                      Phát triển TP Hồ Chí Minh (HD Bank)
                    </option>
                    <option value="MBBank">Quân Đội (MBBank)</option>
                    <option value="ShinHan Bank">Shinhan Việt Nam</option>
                    <option value="TPBank">Tiên Phong (TPBank)</option>
                    <option value="Vietbank">
                      Việt Nam Thương Tín (Vietbank)
                    </option>
                    <option value="BacA Bank">Bắc Á (BacABank)</option>
                    <option value="Ban Viet">Bản Việt (BanViet)</option>
                    <option value="Baoviet Bank">
                      Bảo Việt (BaovietBank)
                    </option>
                    <option value="PVComBank">
                      Đại Chúng Việt Nam (PVComBank)
                    </option>
                    <option value="OceanBank">Đại Dương (OceanBank)</option>
                    <option value="GPBank">
                      Dầu khí toàn cầu (GPBank)
                    </option>
                    <option value="Kienlongbank">
                      Kiên Long (Kienlongbank)
                    </option>
                    <option value="OCB">Phương Đông (OCB)</option>
                    <option value="NCB">Quốc dân (NCB)</option>
                    <option value="VietABank">Việt Á (VietABank)</option>
                    <option value="PGBank">
                      Xăng dầu Petrolimex (PGBank)
                    </option>
                    <option value="CAKE">Cake by VPBank</option>
                    <option value="WOORI">WOORI Bank</option>
                    <option value="LioBank">LioBank</option>
                    <option value="IVB">INDOVINABANK</option>
                    <option value="TIMO">BVBANK&nbsp;TIMO</option>
                    <option value="CIMB">
                      NGAN HANG TNHH MTV&nbsp;CIMB&nbsp;VIET NAM
                    </option>
                    <option value="CITI">CITIBANK VIETNAM</option>
                  </select>
                  <span className="error-note" />
                </div>
              </div>

              <div className="frm__item">
                <label className="l-required">  Họ Tên Chủ Tài Khoản NH: </label>
                <div className="iput" id="nickname_error">
                  <input
                    value={form?.account_name}
                    onChange={(e) =>
                      setForm({ ...form, account_name: e.target.value })
                    }
                    type="text"
                    name="bankBindingForm:j_id50"
                    className="h-[40px] w-full form-control-name bank-input"
                    maxLength={30}
                  />
                  <p className="fs-13 mgT-5 color-red">
                    Họ và tên IN HOA và phải có khoảng cách. (Đây là ví dụ:
                    NGUYEN VAN B)
                  </p>
                  <span className="error-note" />
                </div>
              </div>
              <div className="frm__item">
                <label className="l-required"> Số tài khoản:</label>
                <div className="iput" id="nickname_error">
                  <input
                    value={form?.number_bank}
                    onChange={(e) =>
                      setForm({ ...form, number_bank: e.target.value })
                    }
                    type="text"
                    name="bankBindingForm:j_id52"
                    className="h-[40px] w-full number-account form-control-number bank-input"
                    maxLength={20}
                  />
                  <span className="error-note" />
                </div>
              </div>

              <div className="frm__item frm__btn">
                <label />
                <input
                  type="button"
                  className="btn__primary pdT-15 pdB-15 fs-20"
                  defaultValue="Đăng Ký"
                  onClick={handleRegister}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
