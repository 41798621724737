/* eslint-disable jsx-a11y/anchor-is-valid */
import { message, Spin } from "antd";
import requestService from "api/request";
import clsx from "clsx";
import useBreakpoint from "hooks/useBreakpoint";
import { formatNumber } from "lib/helpers/format";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { onSetUser } from "store/app";
import SideMenu from "./SideMenu";

export const dataOpenPrize = {
  Sunday: [
    [{ id: 1, name: "Thái Bình", href: "/mien-bac" }],
    [
      { id: 2, name: "Kon Tum", href: "/mien-trung/xo-so-kon-tum" },
      { id: 3, name: "Khánh Hòa", href: "/mien-trung/xo-so-khanh-hoa" },
    ],
    [
      { id: 4, name: "Kiên Giang", href: "/mien-nam/xo-so-kien-giang" },
      { id: 5, name: "Lâm Đồng", href: "/mien-nam/xo-so-lam-dong" },
      { id: 6, name: "Tiền Giang", href: "/mien-nam/xo-so-tien-giang" },
    ],
  ],
  Monday: [
    [{ id: 7, name: "Hà Nội", href: "/mien-bac" }],
    [
      { id: 8, name: "Phú Yên", href: "/mien-trung/xo-so-phu-yen" },
      {
        id: 9,
        name: "Thừa Thiên Huế",
        href: "/mien-trung/xo-so-thua-thien-hue",
      },
    ],
    [
      { id: 10, name: "Cà Mau", href: "/mien-nam/xo-so-ca-mau" },
      { id: 11, name: "Đồng Tháp", href: "/mien-nam/xo-so-dong-thap" },
      { id: 12, name: "TP.Hồ Chí Minh", href: "/mien-nam/xo-so-ho-chi-minh" },
    ],
  ],
  Tuesday: [
    [{ id: 13, name: "Quảng Bình", href: "/mien-bac" }],
    [
      { id: 14, name: "Đắk Lắk", href: "/mien-trung/xo-so-dak-lak" },
      { id: 15, name: "Quảng Nam", href: "/mien-trung/xo-so-quang-nam" },
    ],
    [
      { id: 16, name: "Bạc Liêu", href: "/mien-nam/xo-so-bac-lieu" },
      { id: 17, name: "Bến Tre", href: "/mien-nam/xo-so-ben-tre" },
      { id: 18, name: "Vũng Tàu", href: "/mien-nam/xo-so-vung-tau" },
    ],
  ],
  Wednesday: [
    [{ id: 19, name: "Bắc Ninh", href: "/mien-bac" }],
    [
      { id: 20, name: "Khánh Hòa", href: "/mien-trung/xo-so-khanh-hoa" },
      { id: 21, name: "Đà Nẵng", href: "/mien-trung/xo-so-da-nang" },
    ],
    [
      { id: 22, name: "Cần Thơ", href: "/mien-nam/xo-so-can-tho" },
      { id: 23, name: "Đồng Nai", href: "/mien-nam/xo-so-dong-nai" },
      { id: 24, name: "Sóc Trăng", href: "/mien-nam/xo-so-soc-trang" },
    ],
  ],
  Thursday: [
    [{ id: 25, name: "Hà Nội", href: "/mien-bac" }],
    [
      { id: 26, name: "Bình Định", href: "/mien-trung/xo-so-binh-dinh" },
      { id: 27, name: "Quảng Bình", href: "/mien-trung/xo-so-quang-binh" },
      { id: 28, name: "Quảng Trị", href: "/mien-trung/xo-so-quang-tri" },
    ],
    [
      { id: 29, name: "An Giang", href: "/mien-nam/xo-so-an-giang" },
      { id: 30, name: "Bình Thuận", href: "/mien-nam/xo-so-binh-thuan" },
      { id: 31, name: "Tây Ninh", href: "/mien-nam/xo-so-tay-ninh" },
    ],
  ],
  Friday: [
    [{ id: 32, name: "Hải Phòng", href: "/mien-bac" }],
    [
      { id: 33, name: "Gia Lai", href: "/mien-trung/xo-so-gia-lai" },
      { id: 34, name: "Ninh Thuận", href: "/mien-trung/xo-so-ninh-thuan" },
    ],
    [
      { id: 35, name: "Bình Dương", href: "/mien-nam/xo-so-binh-duong" },
      { id: 36, name: "Trà Vinh", href: "/mien-nam/xo-so-tra-vinh" },
      { id: 37, name: "Vĩnh Long", href: "/mien-nam/xo-so-vinh-long" },
    ],
  ],
  Saturday: [
    [{ id: 38, name: "Nam Định", href: "/mien-bac" }],
    [
      { id: 39, name: "Đà Nẵng", href: "/mien-trung/xo-so-da-nang" },
      { id: 40, name: "Đắk Nông", href: "/mien-trung/xo-so-dak-nong" },
      { id: 41, name: "Quảng Ngãi", href: "/mien-trung/xo-so-quang-ngai" },
    ],
    [
      { id: 42, name: "TP. Hồ Chí Minh", href: "/mien-nam/xo-so-ho-chi-minh" },
      { id: 43, name: "Bình Phước", href: "/mien-nam/xo-so-binh-phuoc" },
      { id: 44, name: "Hậu Giang", href: "/mien-nam/xo-so-hau-giang" },
      { id: 45, name: "Long An", href: "/mien-nam/xo-so-long-an" },
    ],
  ],
};

const Header = () => {
  const { user } = useAppSelector((state) => state.app) as any;
  const navigate = useNavigate();
  const [time, setTime] = useState(new Date());
  const handleChangePage = (name: any) => {
    if (!user) return message.error("Vui lòng đăng nhập!");
    return navigate("/xoso/" + name);
  };
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [form, setForm] = useState<any>();
  function formatDate(date: any) {
    const days = [
      "Ngày",
      "Thứ Hai",
      "Thứ Ba",
      "Thứ Tư",
      "Thứ Năm",
      "Thứ Sáu",
      "Thứ Bảy",
    ];
    const months = [
      "Tháng 01",
      "Tháng 02",
      "Tháng 03",
      "Tháng 04",
      "Tháng 05",
      "Tháng 06",
      "Tháng 07",
      "Tháng 08",
      "Tháng 09",
      "Tháng 10",
      "Tháng 11",
      "Tháng 12",
    ];

    const day = date.getDate();
    const month = date.getMonth(); // 0-based index for months
    const year = date.getFullYear();

    return `${days[0]} ${day} ${months[month]} ${year}`;
  }
  const handleLogout = async () => {
    try {
      const res = await requestService.post("/profile/logout");
      if (res && res.data) {
        window.location.reload();
        localStorage.clear();
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message);
    }
  };
  const breakpoint = useBreakpoint();
  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    // Dọn dẹp khi component bị tháo gỡ
    return () => clearInterval(timer);
  }, []);

  const handleLogin = async (e: any) => {
    e.preventDefault();

    setLoading(true);

    try {
      const res = await requestService.post("/auth/login", {
        data: {
          ...form,
        },
      });
      if (res && res.data) {
        dispatch(onSetUser(res?.data?.data?.user));
        localStorage.setItem(
          "accessToken",
          res?.data?.data?.tokens?.accessToken
        );
        localStorage.setItem(
          "refreshToken",
          res?.data?.data?.tokens?.refreshToken
        );
        message.success(res?.data?.message);
        navigate("/");
      }
    } catch (error: any) {
      console.log(error);

      message.error(error?.response?.data?.message);
    }
    setLoading(false);
  };

  const hours = String(time.getHours()).padStart(2, "0");
  const minutes = String(time.getMinutes()).padStart(2, "0");
  const seconds = String(time.getSeconds()).padStart(2, "0");
  const timeString = `${hours}:${minutes}:${seconds}`;
  function startClock() {
    function updateClock() {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");
      const timeString = `${hours}:${minutes}:${seconds}`;

      console.log(timeString); // In ra thời gian hiện tại vào console (hoặc có thể thay đổi theo yêu cầu)
    }

    updateClock(); // Cập nhật đồng hồ ngay lập tức khi gọi hàm
    setInterval(updateClock, 1000); // Cập nhật đồng hồ mỗi giây
  }
  const [open, setOpen] = useState(false);
  return (
    <>
      <Spin spinning={loading} fullscreen />
      <SideMenu open={open} onClose={() => setOpen(false)} />
      {breakpoint !== "mobile" && (
        <div id="header">
          <div className="container">
            <div className="logo">
              <Link to="/">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                  />
                </svg>
              </Link>
              <span id="lx-date">{formatDate(new Date())}</span>
              <span id="lx-timer">{timeString}</span>
            </div>
            <div className="user__auth">
              <input type="hidden" id="checkuser" defaultValue="" />
              <input type="hidden" id="flagLogin" defaultValue={1} />

              {/* User login */}

              {user ? (
                <div className="user__logged">
                  <ul className="logged__act clearfix">
                    <li>
                      <a className="feedbacka" target="_blank">
                        <div className="line">
                          <span className="icon-feedback" />
                          Góp Ý
                        </div>
                      </a>
                    </li>
                    <li className="hover__faceIn">
                      <a className="username">
                        <div className="line !flex items-center gap-2">
                          <img
                            src="/images/avatar/default_avatar.jpg"
                            className="img-circle avatar"
                          />{" "}
                          <span id="nickname_header">{user?.user_name}</span>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m19.5 8.25-7.5 7.5-7.5-7.5"
                              />
                            </svg>
                          </span>
                        </div>
                      </a>
                      <div className="submenu hover__faceIn--wrap">
                        <div className="hover__faceIn--content">
                          <table>
                            <tbody>
                              <tr>
                                <td className="w-25">
                                  <span className="flex items-center h-full">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="size-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                                      />
                                    </svg>
                                  </span>
                                  Tài khoản
                                </td>
                                <td>
                                  <Link to="/profile/persional">
                                    Thông tin cá nhân
                                  </Link>

                                  <Link to="/profile/bank">
                                    Tài khoản ngân hàng
                                  </Link>
                                  {/* <a href="/page/TransferMoney.shtml">
                                 Chuyển quỹ
                               </a>
                               <a href="/page/TransferHistory.shtml">
                                 Lịch sử chuyển quỹ
                               </a> */}
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <span className="h-full flex items-center">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="size-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                      />
                                    </svg>
                                  </span>
                                  Lịch sử
                                </td>
                                <td>
                                  <br />
                                  <Link to="/profile/history-bet">
                                    Lịch sử cược xổ xố
                                  </Link>
                                </td>
                              </tr>
                              {/* <tr>
                             <td>
                               <span className="icon-notifications" />
                               Thông báo
                             </td>
                             <td>
                               <a href="/page/Index.shtml">Tất cả thông báo</a>
                             </td>
                           </tr> */}
                              {/* <tr>
                             <td>
                               <span className="icon-pie-chart" />
                               Báo cáo
                             </td>
                             <td>
                               <a href="/page/ReportWL.shtml">
                                 Báo cáo đại lý
                               </a>
                               <a href="/page/ReportTD.shtml">Báo cáo nhóm</a>
                               <a href="/page/ReportDay.shtml">
                                 Báo cáo hàng ngày
                               </a>
                             </td>
                           </tr> */}
                              <tr className="highlight__row">
                                <td>
                                  <span className="flex items-center h-full">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="size-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                                      />
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                      />
                                    </svg>
                                  </span>
                                  Theo dõi nhanh
                                </td>
                                <td>
                                  <Link to="profile/deposit">
                                    <div className="flex items-center gap-2">
                                      <span>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="currentColor"
                                          className="size-6"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
                                          />
                                        </svg>
                                      </span>
                                      Nạp tiền/Chuyển khoản
                                    </div>
                                  </Link>
                                  {/* <a>
                                    <div className="flex items-center gap-2">
                                      <span>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="currentColor"
                                          className="size-6"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
                                          />
                                        </svg>
                                      </span>
                                      Rút tiền
                                    </div>
                                  </a> */}
                                  {/* <a href="/page/AgentReg.shtml">
                                 <span className="icon-person_add" />
                                 Đăng ký thành viên
                               </a>
                               <a href="/page/question.shtml">
                                 <span className="icon-creditcard" />
                                 Câu hỏi thường gặp
                               </a> */}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="cash__real">
                        <div className="line !flex items-center gap-2">
                          <a className="refresh__cash">
                            <span className="unit__vnd">VNĐ</span>
                            <span id="ebalance">
                              {" "}
                              {formatNumber(user?.real_balance)}
                            </span>
                          </a>
                          <span className="loading__mini loading12x12" />
                          <a className="toggle__cash" title="Ẩn số tiền">
                            <span className="icon-see-balance icons2" />
                          </a>
                        </div>
                      </div>
                    </li>
                    {/* <li>
                   <a
                     href="/page/PersonMsg.shtml"
                     className="letter__alert alert__tip--show"
                   >
                     <div className="line">
                       <span className="icon-envelope" />
                     </div>
                   </a>
                 </li> */}
                    <li>
                      <Link to="/profile/deposit" className="push__money">
                        <div className="line !flex items-center gap-2">
                          <span className="">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
                              />
                            </svg>
                          </span>
                          Nạp Tiền/Chuyển khoản
                        </div>
                      </Link>
                    </li>
                    {/* <li>
                      <a className="pull__money">
                        <div className="line !flex items-center gap-2">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
                              />
                            </svg>
                          </span>
                          Rút Tiền
                        </div>
                      </a>
                    </li> */}
                    {/* <li>
                   <a
                     href="/page/TransferMoney.shtml"
                     className="transfer__money"
                   >
                     <div className="line">
                       <span className="icon-transfer-money" />
                       Chuyển quỹ
                     </div>
                   </a>
                 </li> */}
                    <li>
                      <a className="logout" onClick={handleLogout}>
                        <div className="line !flex items-center gap-2">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M5.636 5.636a9 9 0 1 0 12.728 0M12 3v9"
                              />
                            </svg>
                          </span>
                          Đăng Xuất
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="user__login">
                  <div className="frm__item">
                    <span className="icon-person">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                    </span>
                    <input
                      id="eun"
                      type="text"
                      placeholder="Tên Đăng Nhập"
                      value={form?.email}
                      onChange={(e) =>
                        setForm({ ...form, email: e.target.value })
                      }
                    />
                  </div>
                  <div className="frm__item">
                    <span className="icon-locked">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                        />
                      </svg>
                    </span>
                    <input
                      id="epwd"
                      maxLength={16}
                      type="password"
                      placeholder="Mật Khẩu"
                      value={form?.password}
                      onChange={(e) =>
                        setForm({ ...form, password: e.target.value })
                      }
                    />
                  </div>
                  <div className="frm__item">
                    <label
                      className="r__c--emu r__c--emu13x13"
                      style={{ fontSize: 12, color: "#dedede" }}
                    >
                      <div
                        className="r__c--ui"
                        style={{ marginRight: "unset" }}
                      >
                        <input type="checkbox" id="rememLogin" />
                        <span className="check" /> <span className="checked" />
                      </div>
                      Nhớ mật khẩu
                    </label>
                  </div>
                  <a
                    href="javascript:loginForgetPwd();"
                    className="forgot__pass"
                  >
                    Quên mật khẩu?
                  </a>
                  <button
                    type="button"
                    className="btn__login btn !flex items-center justify-center"
                    onClick={handleLogin}
                  >
                    Đăng nhập
                  </button>
                  <button
                    type="button"
                    className="btn__login btn !flex items-center justify-center"
                    onClick={() => navigate("/register")}
                  >
                    Đăng ký
                  </button>
                </div>
              )}

              {/* Forgot password */}
              <div className="popup" id="popupForgetPwd">
                <div className="popup__content">
                  <input
                    type="hidden"
                    name="j_id3:AgentForm"
                    defaultValue="j_id3:AgentForm"
                  />
                  <span id="j_id3:AgentForm:inputMain">
                    <div className="mgB-25">
                      <p className="mgB-10 font-500 fs-18">
                        Thông tin khác hàng.
                      </p>
                      <p className="fs-16" />
                    </div>
                    <div>
                      <div className="frm__item">
                        <label className="text-right w-30 font-400">
                          <span className="color-red">*</span>Tên đăng nhập：
                        </label>
                        <input
                          type="text"
                          name="j_id3:AgentForm:j_id17"
                          className="input_Game w-40 h-35"
                          maxLength={30}
                        />
                      </div>
                      <div className="frm__item">
                        <label className="text-right w-30 font-400">
                          <span className="color-red">*</span>Địa chỉ mail：
                        </label>
                        <input
                          type="text"
                          name="j_id3:AgentForm:j_id19"
                          className="input_Game  w-40 h-35"
                          maxLength={30}
                        />
                        <span className="d-ib mgL-5 fs-13 fst-i text-color">
                          ( mail khi đăng ký )
                        </span>
                      </div>
                      <div className="frm__item">
                        <label className="text-right w-30 font-400">
                          <span className="color-red">*</span>Mật khẩu thanh
                          toán：
                        </label>
                        <input
                          type="password"
                          name="j_id3:AgentForm:j_id21"
                          defaultValue=""
                          maxLength={30}
                          className="input_Game  w-40 h-35"
                        />
                      </div>
                      <div className="frm__item">
                        <label className="text-right w-30 font-400">
                          <span className="color-red">*</span>Mật khẩu mới：
                        </label>
                        <input
                          type="password"
                          name="j_id3:AgentForm:j_id23"
                          defaultValue=""
                          maxLength={30}
                          className="input_Game  w-40 h-35"
                        />
                      </div>
                      <div className="frm__btn frm__item">
                        <label className="text-right w-30 font-400" />
                        <input
                          className="input_btn_ btn__primary pdT-10 pdB-10 w-40"
                          id="j_id3:AgentForm:j_id25"
                          name="j_id3:AgentForm:j_id25"
                          defaultValue="Cập nhật"
                          type="button"
                        />
                      </div>
                    </div>
                  </span>
                  <input
                    type="hidden"
                    name="javax.faces.ViewState"
                    defaultValue="j_id3"
                  />
                </div>
              </div>
            </div>
            {/*<div class="header__tet"><img src="../images/tet-header.jpg?1722668042245" alt=""/></div>*/}
          </div>
        </div>
      )}

      <div
        id="menu"
        className={clsx({
          "flex flex-col !min-w-full": breakpoint === "mobile",
        })}
      >
        <div className="flex justify-between items-center md:w-1/3">
          <Link to="/" className="logo-left center-horizontal !m-0"></Link>

          <div className="flex gap-3 items-center sm:hidden">
            {user ? (
              <>
                <div className="flex flex-col">
                  <div className="font-[900] text-[12px]">Tổng số dư</div>
                  <div className="font-[900] text-[12px]">
                    {formatNumber(user?.real_balance)}
                  </div>
                </div>
                <Link to="/profile/deposit">
                  <div className="flex items-center gap-2 bg-[#ae0001] text-[#fff] p-1 rounded-lg">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-8"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
                        />
                      </svg>
                    </span>
                    Nạp tiền/Chuyển khoản
                  </div>
                </Link>
                <svg
                  className="icon "
                  onClick={() => setOpen(true)}
                  width={20}
                  height={20}
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    fill: "white",
                    flexShrink: 0,
                  }}
                >
                  <path
                    d="M1024 113.778c0 27.491-21.434 49.778-47.872 49.778h-928.256c-26.439 0-47.872-22.286-47.872-49.778s21.433-49.778 47.872-49.778h928.256c26.438 0 47.872 22.286 47.872 49.778zM1024 910.221c0 27.491-21.434 49.779-47.872 49.779h-928.256c-26.439 0-47.872-22.288-47.872-49.779s21.433-49.776 47.872-49.776h928.256c26.438 0 47.872 22.285 47.872 49.776zM972.8 561.776c26.438 0 47.872-22.285 47.872-49.776 0-27.494-21.434-49.779-47.872-49.779h-617.728c-26.438 0-47.872 22.285-47.872 49.779 0 27.491 21.433 49.776 47.872 49.776h617.728z"
                    style={{ fill: "#777" }}
                  />
                </svg>
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="btn__login btn !flex items-center justify-center"
                  onClick={() => navigate("/login")}
                >
                  Đăng nhập
                </button>
                <button
                  type="button"
                  className="btn__login btn !flex items-center justify-center"
                  onClick={() => navigate("/register")}
                >
                  Đăng ký
                </button>
              </>
            )}
          </div>
        </div>

        {breakpoint === "desktop" && (
          <div className="nav__result !justify-between ">
            <ul className="clearfix parent__menu">
              <li className="home__a">
                <a href="/home.shtml">
                  <span className="icon-home" />
                </a>
              </li>
              <li className="hover__faceIn xsmn" id="htmlLotteryMN">
                <a onClick={() => handleChangePage("xsmn")}>
                  <span className="txt__menu text-[18px] font-[700]">Miền Nam</span>
                </a>
                {/* <div className="hover__faceIn--wrap">
                  <div className="hover__faceIn--content">
                    <div className="tbl">
                      <div className="tbl__row">
                        <div className="tbl__col">T2</div>
                        <div className="tbl__col">
                          {dataOpenPrize.Monday[2].map((i) => (
                            <a
                              className="get-value-T2"
                              onClick={() => handleChangePage(i)}
                            >
                              xổ số {i.name}
                            </a>
                          ))}
                        </div>
                      </div>
                      <div className="tbl__row">
                        <div className="tbl__col">T3</div>
                        <div className="tbl__col">
                          {dataOpenPrize.Tuesday[2].map((i) => (
                            <a
                              className="get-value-T2"
                              onClick={() => handleChangePage(i)}
                            >
                              xổ số {i.name}
                            </a>
                          ))}
                        </div>
                      </div>
                      <div className="tbl__row">
                        <div className="tbl__col">T4</div>
                        <div className="tbl__col">
                          {dataOpenPrize.Wednesday[2].map((i) => (
                            <a
                              className="get-value-T2"
                              onClick={() => handleChangePage(i)}
                            >
                              xổ số {i.name}
                            </a>
                          ))}
                        </div>
                      </div>
                      <div className="tbl__row">
                        <div className="tbl__col">T5</div>
                        <div className="tbl__col">
                          {dataOpenPrize.Thursday[2].map((i) => (
                            <a
                              className="get-value-T2"
                              onClick={() => handleChangePage(i)}
                            >
                              xổ số {i.name}
                            </a>
                          ))}
                        </div>
                      </div>
                      <div className="tbl__row">
                        <div className="tbl__col">T6</div>
                        <div className="tbl__col">
                          {dataOpenPrize.Friday[2].map((i) => (
                            <a
                              className="get-value-T2"
                              onClick={() => handleChangePage(i)}
                            >
                              xổ số {i.name}
                            </a>
                          ))}
                        </div>
                      </div>
                      <div className="tbl__row">
                        <div className="tbl__col">T7</div>
                        <div className="tbl__col">
                          {dataOpenPrize.Saturday[2].map((i) => (
                            <a
                              className="get-value-T2"
                              onClick={() => handleChangePage(i)}
                            >
                              xổ số {i.name}
                            </a>
                          ))}
                   
                        </div>
                      </div>
                      <div className="tbl__row">
                        <div className="tbl__col">CN</div>
                        <div className="tbl__col">
                          {dataOpenPrize.Sunday[2].map((i) => (
                            <a
                              className="get-value-T2"
                              onClick={() => handleChangePage(i)}
                            >
                              xổ số {i.name}
                            </a>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </li>
              <li className="hover__faceIn xsmt" id="htmlLotteryMT">
                <a onClick={() => handleChangePage("xsmt")}>
                  <span className="txt__menu text-[18px] font-[700]">Miền Trung</span>
                </a>
                {/* <div className="hover__faceIn--wrap">
                  <div className="hover__faceIn--content">
                    <div className="tbl">
                      <div className="tbl__row">
                        <div className="tbl__col">T2</div>
                        <div className="tbl__col">
                          {dataOpenPrize.Monday[1].map((i) => (
                            <a
                              className="get-value-T2"
                              onClick={() => handleChangePage(i)}
                            >
                              xổ số {i.name}
                            </a>
                          ))}
                        </div>
                      </div>
                      <div className="tbl__row">
                        <div className="tbl__col">T3</div>
                        <div className="tbl__col">
                          {dataOpenPrize.Tuesday[1].map((i) => (
                            <a
                              className="get-value-T2"
                              onClick={() => handleChangePage(i)}
                            >
                              xổ số {i.name}
                            </a>
                          ))}
                        </div>
                      </div>
                      <div className="tbl__row">
                        <div className="tbl__col">T4</div>
                        <div className="tbl__col">
                          {dataOpenPrize.Wednesday[1].map((i) => (
                            <a
                              className="get-value-T2"
                              onClick={() => handleChangePage(i)}
                            >
                              xổ số {i.name}
                            </a>
                          ))}
                        </div>
                      </div>
                      <div className="tbl__row">
                        <div className="tbl__col">T5</div>
                        <div className="tbl__col">
                          {dataOpenPrize.Thursday[1].map((i) => (
                            <a
                              className="get-value-T2"
                              onClick={() => handleChangePage(i)}
                            >
                              xổ số {i.name}
                            </a>
                          ))}
                        </div>
                      </div>
                      <div className="tbl__row">
                        <div className="tbl__col">T6</div>
                        <div className="tbl__col">
                          {dataOpenPrize.Friday[1].map((i) => (
                            <a
                              className="get-value-T2"
                              onClick={() => handleChangePage(i)}
                            >
                              xổ số {i.name}
                            </a>
                          ))}
                        </div>
                      </div>
                      <div className="tbl__row">
                        <div className="tbl__col">T7</div>
                        <div className="tbl__col">
                          {dataOpenPrize.Saturday[1].map((i) => (
                            <a
                              className="get-value-T2"
                              onClick={() => handleChangePage(i)}
                            >
                              xổ số {i.name}
                            </a>
                          ))}
                        </div>
                      </div>
                      <div className="tbl__row">
                        <div className="tbl__col">CN</div>
                        <div className="tbl__col">
                          {dataOpenPrize.Sunday[1].map((i) => (
                            <a
                              className="get-value-T2"
                              onClick={() => handleChangePage(i)}
                            >
                              xổ số {i.name}
                            </a>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </li>
              <li className="hover__faceIn xs__fast sub__gameAll">
                <a onClick={() => handleChangePage("xsmb")}>
                  <span className="txt__menu text-[18px] font-[700]">Miền Bắc</span>
                </a>
                <div className="hover__faceIn--wrap third-game-menu">
                  {/* <div className="hover__faceIn--content">
                    <ul>
                      <li className="northLottery">
                        <a onClick={() => handleChangePage(1)}>
                          <span className="logo-game north-lottery" />
                          Xổ Số Miền Bắc
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </li>
              {/* <li
              className="hover__faceIn xs__fast sub__gameAll"
              id="htmlLotteryDanGian"
            >
              <a className="txt__menu">
                <span>Games Dân Gian</span>
              </a>
            </li> */}
              {/* <li
                className="hover__faceIn xs__fast sub__gameAll"
                id="theThaoHTML"
              >
                <a className="txt__menu">
                  <span>Thể Thao</span>
                </a>
              </li> */}
              <li
                className="hover__faceIn xs__fast sub__gameAll"
                id="troChoiHTML"
              />
              {/* <li
                className="hover__faceIn xs__fast sub__gameAll"
                id="slotsHTML"
              >
                <a className="txt__menu">
                  <span>Slot Game</span>
                </a>
              </li>
              <li
                className="hover__faceIn xs__fast sub__gameAll"
                id="gameBaiHTML"
              >
                <a className="txt__menu">
                  <span>Game Bài</span>
                </a>
              </li>
              <li
                className="hover__faceIn xs__fast sub__gameAll"
                id="liveCasinoHTML"
              >
                <a className="txt__menu">
                  <span>Live Casino</span>
                </a>
              </li>
              <li className="hover__faceIn xs__fast sub__gameAll">
                <a>Bắn Cá</a>
              </li> */}

            </ul>
            <li className="hover__faceIn promoMenu hight-light">
              <a>
                <span className="txt__menu">Khuyến mãi</span>
              </a>
              <div className="hover__faceIn--wrap">
                <div className="hover__faceIn--content">
                  <ul id="promo" className="">
                    <form
                      id="promoPage"
                      name="promoPage"
                      method="post"
                      action="/home.shtml"
                      encType="application/x-www-form-urlencoded"
                    >
                      <input
                        type="hidden"
                        name="promoPage"
                        defaultValue="promoPage"
                      />
                      <li>
                        <a
                          href="#"
                          title="Hoàn trả 88% cược thua Thể thao"
                          target="_blank"
                        >
                          Nạp tiền từ 1.000.000đ đến dưới 20.000.000đ được thưởng 5%
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          title="KHUYẾN MÃI NẠP LẦN ĐẦU"
                          target="_blank"
                        >
                          Nạp tiền từ 20.000.000đ đến dưới 40.000.000đ được thưởng 10%
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          title="CHƯƠNG TRÌNH ĐIỂM DANH NHẬN QUÀ"
                          target="_blank"
                        >
                          Nạp tiền từ 40.000.000đ đến dưới 60.000.000đ được thưởng 15%

                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          title="Chương trình vòng quay may mắn"
                          target="_blank"
                        >
                          Nạp tiền từ 60.000.000đ được thưởng 20%
                        </a>
                      </li>
                      {/* <li>
                          <a
                            href="/promotions.shtml?promoID=157"
                            title="GIỚI THIỆU NGƯỜI MỚI THAM GIA"
                            target="_blank"
                          >
                            GIỚI THIỆU NGƯỜI MỚI THAM GIA
                          </a>
                        </li>
                        <li>
                          <a
                            href="/promotions.shtml?promoID=35"
                            title="HOÀN CƯỢC ĐẾN 388.888.000 VNĐ"
                            target="_blank"
                          >
                            HOÀN CƯỢC ĐẾN 388.888.000 VNĐ
                          </a>
                        </li>
                        <li>
                          <a
                            href="/promotions.shtml?promoID=155"
                            title="VUI  XUÂN  HOÀN NẠP SIÊU KHỦNG ĐẾN 0.88%"
                            target="_blank"
                          >
                            VUI XUÂN HOÀN NẠP SIÊU KHỦNG ĐẾN 0.88%
                          </a>
                        </li>
                        <li>
                          <a
                            href="/promotions.shtml?promoID=154"
                            title="HOÀN ĐẾN 1% THỂ THAO MỖI NGÀY (MỚI)"
                            target="_blank"
                          >
                            HOÀN ĐẾN 1% THỂ THAO MỖI NGÀY (MỚI)
                          </a>
                        </li>
                        <li>
                          <a
                            href="/promotions.shtml?promoID=151"
                            title="HOÀN CƯỢC XỔ SỐ SÀI GÒN VIP ĐẾN 1%"
                            target="_blank"
                          >
                            HOÀN CƯỢC XỔ SỐ SÀI GÒN VIP ĐẾN 1%
                          </a>
                        </li>
                        <li>
                          <a
                            href="/promotions.shtml?promoID=150"
                            title="CHÀO ĐÓN XỔ SỐ LIVESTREAM"
                            target="_blank"
                          >
                            CHÀO ĐÓN XỔ SỐ LIVESTREAM
                          </a>
                        </li>
                        <li>
                          <a
                            href="/promotions.shtml?promoID=43"
                            title="HOÀN 5% CƯỢC THUA MỖI NGÀY"
                            target="_blank"
                          >
                            HOÀN 5% CƯỢC THUA MỖI NGÀY
                          </a>
                        </li>
                        <li>
                          <a
                            href="/promotions.shtml?promoID=6"
                            title="Chơi miễn phí - Rút tiền về ví"
                            target="_blank"
                          >
                            Chơi miễn phí - Rút tiền về ví
                          </a>
                        </li> */}
                      <input
                        type="hidden"
                        name="javax.faces.ViewState"
                        defaultValue="j_id3"
                      />
                    </form>
                  </ul>
                </div>
              </div>
            </li>
          </div>
        )}

        {/* <a className="chat-box-right center-horizontal">
          <span>Hỗ trợ</span>
        </a>
        <input type="hidden" id="checkUserType" defaultValue={0} /> */}
      </div>
    </>
  );
};

export default Header;
