import requestService from "api/request";
import useBreakpoint from "hooks/useBreakpoint";
import { formatNumber } from "lib/helpers/format";
import React, { useEffect, useState } from "react";

const HistoryRecharge = () => {
  const [datas, setData] = useState<any>([]);

  const getData = async () => {
    try {
      const res = await requestService.get("/profile/getHisstoryPayment");
      if (res && res.data) {
        setData(res.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const breakpoint = useBreakpoint();
  return (
    <div className="tbl__record">
      <span id="GameRecordForm:pgData">
        <div className="wrap__tbl">
          <table>
            <tbody>
              <tr
                className=""
                style={{
                  background: "-webkit-linear-gradient(#FFCA53, #ff7b02)",
                }}
              >
                {breakpoint === "desktop" && (
                  <>
                    <td className="w-5">
                      <span>STT</span>
                    </td>
                    <td className="w-13">
                      <span>Mã số</span>
                    </td>
                  </>
                )}

                <td className="w-3 md:w-15">
                  <span>Giá trị</span>
                </td>
                <td className="w-3 md:w-15">
                  <span>Thời gian</span>
                </td>

                <td className="w-8">
                  <span>Trạng thái</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="tbl__record--bottom">
          <div className="tbl__pagi clearfix">
            <div className="pull-right pagi__wrap">
              <table>
                <tbody>
                  {datas?.map((i: any, index: number) => (
                    <tr className="" key={index}>
                      {breakpoint === "desktop" && (
                        <>
                          <td className="w-5">
                            <span>{index}</span>
                          </td>

                          <td className="w-13">
                            <span>{i?._id}</span>
                          </td>
                        </>
                      )}

                      <td className="w-10 sm:w-15">
                        <div className="flex flex-col gap-1">
                        {breakpoint === "mobile" && <span>{i?._id}</span>}
                        <span>{formatNumber(i?.value)}</span>
                        </div>
                      
                      </td>
                      <td className="w-15">
                        <span>
                          {new Date(i?.createdAt)?.toLocaleDateString()}
                        </span>
                      </td>

                      <td className="w-5 sm:w-8">
                        <span>{i?.transaction_status}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </span>
    </div>
  );
};

export default HistoryRecharge;
