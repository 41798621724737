import { Spin } from "antd";
import requestService from "api/request";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const HistoryPlay = () => {
  const [history, setHistory] = useState<any>([]);
  const [loading,setLoading] = useState(false)

  const getHistory = async () => {
    setLoading(true)
    try {
      const res = await requestService.get("/profile/history-transaction", {
        params: {
          transaction_status: "finish",
          transaction_type: "ref",
        },
      });
      if (res && res.data) {
        setHistory(res.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false)
  };

  useEffect(() => {
    getHistory();
  }, []);
  return (
    <>
    <Spin spinning={loading} fullscreen/>
      <div className="app1">
        <div className="flex flex-col gap-1 w-full">
          {history?.length > 0 &&
            history?.map((i: any, index: number) => (
              <div
                className="flex items-center gap-1 justify-between  border-y py-2 px-3"
                key={index}
              >
                <div className="flex gap-1 items-center">
                  <img src="/images/badge.png" width={32} alt="" />
                  <div className="flex flex-col text-left">
                    <div className="font-[700] text-[14px] text-[#fff]">
                      Reward Ref
                    </div>
                    <div className="font-[500] text-[15px] text-[#ccc]">
                      {i?.refUser?.email || "-"}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="text-green-400 font-[700] text-[20px]">
                    +{i?.value}$
                  </div>
                  <div className=" font-[700] text-[12px] text-[#ccc]">
                    {new Date(i?.createdAt)?.toLocaleString()}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default HistoryPlay;
