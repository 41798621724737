import type { RouteObject } from "react-router";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import Login from "../pages/login";
import WrapperRouteComponent from "./WrapperRouteComponent";
import Register from "pages/register";
import Home from "pages/home";
import Footer from "components/Footer";
import Game from "pages/game";
import Profile from "pages/profile";
import HistoryPlay from "pages/historyplay";
import Award from "pages/award";
import Withdraw from "pages/withdraw";
import AddBank from "pages/add-bank";
import ResetPassword from "pages/reset-password";
import { useEffect } from "react";
import { getJSONFromUrl } from "lib/helpers/format";
import Team from "pages/team";
import Header from "components/Header";
import Banner from "components/Banner";
import Lottery from "pages/lottery";
import Bank from "pages/profile/components/Bank";
import { Persional } from "pages/profile/components/Persional";
import Deposit from "pages/profile/components/Deposit";
import HistoryRecharge from "pages/profile/components/HistoryRecharge";
import HistoryBet from "pages/profile/components/HistoryBet";
const routeList: RouteObject[] = [
  // {
  //   path: "/",
  //   element: (
  //     <WrapperRouteComponent element={<Home />} />
  //   ),
  // },

  {
    path: "/game",
    element: <WrapperRouteComponent element={<Game />} />,
  },
  {
    path: "/historyplay",
    element: <WrapperRouteComponent element={<HistoryPlay />} />,
  },
  {
    path: "/award",
    element: <WrapperRouteComponent element={<Award />} />,
  },
  {
    path: "/",
    element: <WrapperRouteComponent element={<Home />} />,
  },
  {
    path: "/profile",
    element: <WrapperRouteComponent element={<Profile />} />,
    children: [
      {
        path: "bank",
        element: <WrapperRouteComponent element={<Bank />} />,
      },
      {
        path: "persional",
        element: <WrapperRouteComponent element={<Persional />} />,
      },
      {
        path: "deposit",
        element: <WrapperRouteComponent element={<Deposit />} />,
      },
      {
        path: "history-recharge",
        element: <WrapperRouteComponent element={<HistoryRecharge />} />,
      },
      {
        path: "history-bet",
        element: <WrapperRouteComponent element={<HistoryBet />} />,
      },
    ],
  },
  {
    path: "/withdraw",
    element: <WrapperRouteComponent element={<Withdraw />} />,
  },
  {
    path: "/addbank",
    element: <WrapperRouteComponent element={<AddBank />} />,
  },
  {
    path: "/team",
    element: <WrapperRouteComponent element={<Team />} />,
  },
  {
    path: "/xoso/:id",
    element: <WrapperRouteComponent element={<Lottery />} />,
  },
  // {
  //   path: "/xoso/:category",
  //   element: <WrapperRouteComponent element={<Lottery />} />,
  // },
  {
    path: "/resetpassword",
    element: <WrapperRouteComponent element={<ResetPassword />} />,
  },
  {
    path: "/login",
    element: <WrapperRouteComponent element={<Login />} />,
  },
  {
    path: "/register",
    element: <WrapperRouteComponent element={<Register />} />,
  },
];
const RenderRouter = () => {
  const element = useRoutes(routeList);
  const navigate = useNavigate();
  const location = useLocation();
  // useEffect(() => {
  //   let { r } = getJSONFromUrl();
  //   if (!localStorage.getItem("accessToken")) {
  //     if (r) return navigate(`/register?r=${r}`);

  //     return navigate("/login");
  //   }
  //   if (
  //     localStorage.getItem("accessToken") &&
  //     (location.pathname === "/login" || location.pathname === "/register")
  //   )
  //     return navigate("/");
  // }, []);
  return (
    <>
      <Header />
      <Banner />
      {element}
    </>
  );
};

export default RenderRouter;
