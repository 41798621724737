import { Spin, message } from "antd";
import requestService from "api/request";
import { validateEmail, validatePassword } from "lib/helpers/format";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { onSetUser } from "store/app";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { appGlobal } = useAppSelector((state) => state.app) as any;
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const [form, setForm] = useState<any>();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogin = async (e: any) => {
    e.preventDefault();

    setLoading(true);

    try {
      const res = await requestService.post("/auth/login", {
        data: {
          ...form,
        },
      });
      if (res && res.data) {
        dispatch(onSetUser(res?.data?.data?.user));
        localStorage.setItem(
          "accessToken",
          res?.data?.data?.tokens?.accessToken
        );
        localStorage.setItem(
          "refreshToken",
          res?.data?.data?.tokens?.refreshToken
        );
        message.success(res?.data?.message);
        navigate("/");
      }
    } catch (error: any) {
      console.log(error);

      message.error(
        error?.response?.data?.message || "Có lỗi xảy ra thử lại !!!"
      );
    }
    setLoading(false);
  };
  return (
    <>
      <Spin spinning={loading} fullscreen />
      <div className="regPage">
        <div id="container">
          {/* InstanceBeginEditable name="EditRegion2" */}
          <div className="register__page bg__full">
            <div className="inner">
              <div className="d-ib text-left">
                
                <div className="clearfix title">
                  <h2>Đăng nhập Tài Khoản</h2>
                </div>
                <div className="frm__item">
                  <label className="l-required">Tên đăng nhập: </label>
                  <div className="iput" id="username_error">
                    <input
                      type="text"
                      id="lname"
                      placeholder="Không được ít hơn 5 ký tự và nhiều hơn 30 ký tự"
                      value={form?.email}
                      onChange={(e) =>
                        setForm({ ...form, email: e.target.value })
                      }
                    />
                    <span className="error-note" />
                  </div>
                </div>
                <div className="frm__item">
                  <label className="l-required">Mật khẩu đăng nhập: </label>
                  <div className="iput" id="password_error">
                    <input
                      type="password"
                      id="lpwd"
                      placeholder="Không được giống mật khẩu thanh toán"
                      value={form?.password}
                      onChange={(e) =>
                        setForm({ ...form, password: e.target.value })
                      }
                    />
                    <span className="icon-visibility" />
                    <span className="error-note" />
                  </div>
                </div>
                
                
                <div className="frm__item frm__btn">
                  <label />
                  <input
                    type="button"
                    className="btn__primary pdT-15 pdB-15 fs-20"
                    defaultValue="Đăng nhập"
                  onClick={handleLogin}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
