import React, { useEffect } from "react";
import Bank from "./components/Bank";
import { Persional } from "./components/Persional";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import useBreakpoint from "hooks/useBreakpoint";

const Profile = () => {
  const { pathname } = useLocation();
  const breakpoint = useBreakpoint();

  const navigate = useNavigate();
  // useEffect(() => {
  //   navigate("/profile/persional");
  // }, []);
  return (
    <div
      id="container"
      className={clsx({
        "!min-w-full": breakpoint === "mobile",
      })}
    >
      {/* InstanceBeginEditable name="EditRegion2" */}
      <div className="dashboard">
        <div className="dashboard__wrapper">
          <div className="dashboard__wrapper--inner clearfix">
            {breakpoint === "desktop" && (
              <div className="dashboard__menu box__shadowbg">
                <div
                  className="panel-group"
                  id="accordion"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div className="panel__item panel">
                    <a
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                      className="panel__title collapsed "
                    >
                      {" "}
                      <span className="absolute top-1/2 text-[23px] left-[16px] mt-[-14px]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-[23px]"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                          />
                        </svg>
                      </span>
                      Tài Khoản{" "}
                      <span className="absolute top-1/2 right-0 mt-[-12px] text-[25px]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-[25px]"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          />
                        </svg>
                      </span>
                    </a>
                    {/* <div
                    id="collapseOne"
                    className="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingOne"
                    aria-expanded="false"
                    style={{ height: 1 }}
                  >
                    <div className="panel-body">
                      <ul>
                        <li>
                          <a href="/page/PersonPwd.shtml">
                            <span className="icon-navigate_next" />
                            Thông tin cá nhân
                          </a>
                        </li>
                        <li>
                          <a href="/page/PersonMsg.shtml">
                            <span className="icon-navigate_next" />
                            Tin nhắn{" "}
                            <span className="number" id="unreadMsgNumLeftMenu">
                              0
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="/page/PersonBank.shtml" className="active">
                            <span className="icon-navigate_next" />
                            Tài khoản ngân hàng
                          </a>
                        </li>
                        <li>
                          <a href="/page/TransferMoney.shtml">
                            <span className="icon-navigate_next" />
                            Chuyển quỹ
                          </a>
                        </li>
                        <li>
                          <a href="/page/TransferHistory.shtml">
                            <span className="icon-navigate_next" />
                            Lịch sử chuyển quỹ
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  </div>

                  <div className="panel__item panel">
                    <Link
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      to="/profile/history-bet"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                      className="collapsed panel__title"
                    >
                      {" "}
                      <span className="absolute top-1/2 text-[23px] left-[16px] mt-[-14px]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-[23px]"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </span>
                      Lịch sử cược{" "}
                      <span className="absolute top-1/2  right-0 mt-[-12px] text-[25px]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-[25px]"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          />
                        </svg>
                      </span>
                    </Link>
                  </div>

                  <div className="panel__item panel">
                    <a
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                      className="collapsed panel__title"
                      onClick={() => navigate('/profile/deposit')}
                    >
                      {" "}
                      <span className="absolute top-1/2 text-[23px] left-[16px] mt-[-14px]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-[23px]"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </span>
                      Chuyển khoản - Rút tiền
                      <span className="absolute top-1/2  right-0 mt-[-12px] text-[25px]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-[25px]"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            )}

            <div
              className={clsx("dashboard__mana", {
                "!w-full": breakpoint === "mobile",
              })}
            >
              <div className="dashboard__mana--inner">
                <div className="tabs_framed">
                  <div className="inner">
                    {(pathname === "/profile/persional" ||
                      pathname === "/profile/bank") && (
                        <ul className="tabs clearfix">
                          <li
                            className={clsx({
                              active: pathname === "/profile/persional",
                            })}
                          >
                            <Link to="/profile/persional">Thông tin cá nhân</Link>
                          </li>

                          <li
                            className={clsx({
                              active: pathname === "/profile/bank",
                            })}
                          >
                            <Link to="/profile/bank">Tài khoản ngân hàng</Link>
                          </li>
                        </ul>
                      )}
                    {(pathname === "/profile/deposit" ||
                      pathname === "/profile/history-recharge") && (
                      <ul className="tabs clearfix">
                        <li
                          className={clsx({
                            active: pathname === "/profile/deposit",
                          })}
                        >
                          <Link to="/profile/deposit">Chuyển khoản</Link>
                        </li>

                          <li
                            className={clsx({
                              active: pathname === "/profile/history-recharge",
                            })}
                          >
                            <Link to="/profile/history-recharge">
                              Lịch sử nạp tiền
                            </Link>
                          </li>
                        </ul>
                      )}
                    <Outlet />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="footer">
          <div className="ld-footer-inner">
            {/* Certificate, Social link & Register link*/}
            <div className="container">
              <div className="row align-items-center">
                <div className="certificate col-md-6">
                  <img
                    src="/images/lx88/common/comodo.png"
                    alt="Chứng chỉ bảo mật SSL của Comodo và các phương thức thanh toán trên Lixi88."
                    title="Chứng chỉ bảo mật SSL của Comodo và các phương thức thanh toán trên Lixi88."
                  />
                  <img
                    className="d-block mb-2 instant-logo"
                    src="/images/lx88/common/IL_logo.png"
                    alt="Kết quả xổ số nhanh trên Lixi88 được cung cấp và cho phép sử dụng bởi website InstantLottery.co"
                    title="Kết quả xổ số nhanh trên Lixi88 được cung cấp và cho phép sử dụng bởi website InstantLottery.co"
                  />
                  <div className="logo__footer">
                    <img src="/images/logo-tst-pagcor.png?1722668042245" />
                  </div>
                  <p className="color-64">
                    All rights reserved © Copyright 2018
                  </p>
                </div>
                <div className="col-md-6">
                  <ul className="nav-social text-right position-relative">
                    <li className="nav-social-item">
                      <a
                        className="nav-social-item-link face-book"
                        href="https://facebook.com/lixi88vn"
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    </li>
                    <li className="nav-social-item">
                      <a
                        className="nav-social-item-link google-plus"
                        href="https://plus.google.com/b/113577012899025477748/113577012899025477748"
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    </li>
                    <li className="nav-social-item">
                      <a
                        className="nav-social-item-link youtube"
                        href="https://www.youtube.com/channel/UCxKAfhU-TEvh-wDd91wWKMA"
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    </li>
                    <li className="nav-social-item">
                      <a className="nav-social-item-link viber" />
                    </li>
                    <li className="nav-social-item">
                      <a
                        className="nav-social-item-link zalo"
                        href="https://zalo.me/2018834966462934115"
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    </li>
                  </ul>
                  <div className="text-right mt-2">
                    <img
                      src="/images/lx88/common/register-parter.png"
                      alt="Click để đăng ký làm đối tác của Lixi88."
                      title="Click để đăng ký làm đối tác của Lixi88."
                    />
                  </div>
                  <div className="tbl mgT-20">
                    <div className="tbl__row">
                      <div className="tbl__col text-center w-20 ver-c">
                        <img src="/images/18+.png?1722668042245" />
                      </div>
                      <div className="tbl__col">
                        <p className="lh-22 text-justify color-64">
                          Chúng tôi tích cực đẩy mạnh cá cược có trách nhiệm và
                          cương quyết từ chối trẻ vị thành niên sử dụng phần mềm
                          của chúng tôi để cá cược trên mạng.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="back-to-top text-center back__top active">
                <a href="javascript:void(0)" />
              </div>
            </div>
          </div>
          <div className="bottom-link">
            <div className="row">
              <ul className="nav nav-bottom">
                <li className="nav-bottom-item">
                  <a href="/page/AboutUs.shtml">Về chúng tôi</a>
                </li>
                <li className="nav-bottom-item">
                  {" "}
                  <a href="/page/GammerResponsibility.shtml">
                    Cờ bạc có trách nhiệm
                  </a>{" "}
                </li>
                <li className="nav-bottom-item">
                  {" "}
                  <a href="/page/Copyright.shtml">Tuyên bố bản quyền</a>{" "}
                </li>
                <li className="nav-bottom-item">
                  {" "}
                  <a href="/page/PrivacyPolicy.shtml">
                    Chính sách bảo mật
                  </a>{" "}
                </li>
                <li className="nav-bottom-item">
                  {" "}
                  <a href="/page/RulesAndRegulations.shtml">
                    Quy định và luật lệ
                  </a>{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* InstanceEndEditable */}
    </div>
  );
};

export default Profile;
