import React from "react";
import Slider from "react-slick";
const Banner = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="homepage__slide">
      <div
        className="homepage__slide--inner slick-initialized slick-slider slick-dotted"
        id="homepage-slide"
      >
        <div className="slick-list draggable">
          <Slider {...settings}>
            <div
              className="slick-slide"
              data-slick-index={0}
              aria-hidden="true"
              tabIndex={-1}
              role="tabpanel"
              id="slick-slide00"
              aria-describedby="slick-slide-control00"
              style={{
                width: 818,
                position: "relative",
                left: 0,
                top: 0,
                zIndex: 998,
                opacity: 0,
                transition: "opacity 500ms linear",
              }}
            >
              <img src="/images/2024-09-07 13.01.44.jpg" className="w-full" />
            </div>
            <div
              className="slick-slide"
              data-slick-index={0}
              aria-hidden="true"
              tabIndex={-1}
              role="tabpanel"
              id="slick-slide00"
              aria-describedby="slick-slide-control00"
              style={{
                width: 818,
                position: "relative",
                left: 0,
                top: 0,
                zIndex: 998,
                opacity: 0,
                transition: "opacity 500ms linear",
              }}
            >
              <img src="/images/2024-09-07 13.01.44.jpg" className="w-full" />
            </div>
            <div
              className="slick-slide"
              data-slick-index={0}
              aria-hidden="true"
              tabIndex={-1}
              role="tabpanel"
              id="slick-slide00"
              aria-describedby="slick-slide-control00"
              style={{
                width: 818,
                position: "relative",
                left: 0,
                top: 0,
                zIndex: 998,
                opacity: 0,
                transition: "opacity 500ms linear",
              }}
            >
              <img src="/images/2024-09-07 13.01.44.jpg" className="!w-full" />
            </div>

          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Banner;
