import { message } from "antd";
import requestService from "api/request";
import clsx from "clsx";
import useBreakpoint from "hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "store";

const Home = () => {
  const { user } = useAppSelector((state) => state.app) as any;
  const navigate = useNavigate();
  const [datas, setData] = useState<Array<any>>([])
  const breakpoint = useBreakpoint();

  const handleChangePage = (name: any) => {
    if (!user) return message.error("Vui lòng đăng nhập!");
    return navigate("/xoso/" + name);
  };


  const getData = async () => {
    try {
      const res = await requestService.get('/auth/crawResultHomeToday')
      if (res && res.data) {
        setData(res.data?.data)
      }
    } catch (error) {
      console.log(error);

    }
  }

  useEffect(() => {
    getData()
  }, [])
  return (
    <>
      <div className="homepage bg__full">
        <div className="slide-notifi">
          <div className="container">
            <div className="slide-notifi-content">
              <div className="slide-notifi-title">
                <img src="images/icon-new-volume.png" />
                <span className="text">Thông báo:</span>
              </div>
              <div className="show-promoPage"></div>
            </div>
          </div>
        </div>
        <div className="container lx-intro">
          <div className="row p-4">
            <div className="col-12 col-sm-3 mb-3">
              <div
                onClick={() => handleChangePage("xsmb")}
                className="main-frame-MuiStack-root css-mb53rw"
                id="Local Lottery-d2077445-cd11-4ff5-81b3-8fc9bc24ef44"
              >
                <img
                  alt=""
                  loading="lazy"
                  decoding="async"
                  data-nimg="fill"
                  sizes="100vw"
                  src="/images/lx88/vnnorth-bg.jpeg"
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    inset: 0,
                    objectFit: "fill",
                    color: "transparent",
                    flexShrink: 0,
                  }}
                />
                <div className="main-frame-MuiStack-root css-ank6xk">
                  <div className="main-frame-MuiBox-root css-8b3hir">
                    <p
                      className="main-frame-MuiTypography-root main-frame-MuiTypography-body1 tlMD css-fbdf7r !text-[18px] !font-[700]"
                      id="Local Lottery-d2077445-cd11-4ff5-81b3-8fc9bc24ef44-name"
                    >
                      XỔ SỐ MIỀN BẮC
                    </p>
                  </div>
                </div>
                <div className="main-frame-MuiStack-root css-17o253n">
                  <button
                    className="main-frame-MuiButtonBase-root main-frame-MuiButton-root main-frame-MuiButton-contained main-frame-MuiButton-containedPrimary main-frame-MuiButton-sizeSm main-frame-MuiButton-containedSizeSm main-frame-MuiButton-root main-frame-MuiButton-contained main-frame-MuiButton-containedPrimary main-frame-MuiButton-sizeSm main-frame-MuiButton-containedSizeSm buttonXS css-1av77hb"
                    tabIndex={0}
                    type="button"
                    id="Local Lottery-d2077445-cd11-4ff5-81b3-8fc9bc24ef44-play-now"
                  >
                    Chơi Ngay
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-3 mb-3">
              <div
                onClick={() => handleChangePage("xsmn")}
                className="main-frame-MuiStack-root css-mb53rw"
                id="Local Lottery-d2077445-cd11-4ff5-81b3-8fc9bc24ef44"
              >
                <img
                  alt=""
                  loading="lazy"
                  decoding="async"
                  data-nimg="fill"
                  sizes="100vw"
                  src="/images/lx88/vnsouth-bg.jpeg"
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    inset: 0,
                    objectFit: "fill",
                    color: "transparent",
                    flexShrink: 0,
                  }}
                />
                <div className="main-frame-MuiStack-root css-ank6xk">
                  <div className="main-frame-MuiBox-root css-8b3hir">
                    <p
                      className="main-frame-MuiTypography-root main-frame-MuiTypography-body1 tlMD css-fbdf7r !text-[18px] !font-[700]"
                      id="Local Lottery-d2077445-cd11-4ff5-81b3-8fc9bc24ef44-name"
                    >
                      XỔ SỐ MIỀN NAM
                    </p>
                  </div>
                </div>
                <div className="main-frame-MuiStack-root css-17o253n">
                  <button
                    className="main-frame-MuiButtonBase-root main-frame-MuiButton-root main-frame-MuiButton-contained main-frame-MuiButton-containedPrimary main-frame-MuiButton-sizeSm main-frame-MuiButton-containedSizeSm main-frame-MuiButton-root main-frame-MuiButton-contained main-frame-MuiButton-containedPrimary main-frame-MuiButton-sizeSm main-frame-MuiButton-containedSizeSm buttonXS css-1av77hb"
                    tabIndex={0}
                    type="button"
                    id="Local Lottery-d2077445-cd11-4ff5-81b3-8fc9bc24ef44-play-now"
                  >
                    Chơi Ngay
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-3 mb-3">
              <div
                onClick={() => handleChangePage("xsmt")}
                className="main-frame-MuiStack-root css-mb53rw"
                id="Local Lottery-d2077445-cd11-4ff5-81b3-8fc9bc24ef44"
              >
                <img
                  alt=""
                  loading="lazy"
                  decoding="async"
                  data-nimg="fill"
                  sizes="100vw"
                  src="/images/lx88/vncentral-bg.jpeg"
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    inset: 0,
                    objectFit: "fill",
                    color: "transparent",
                    flexShrink: 0,
                  }}
                />
                <div className="main-frame-MuiStack-root css-ank6xk">
                  <div className="main-frame-MuiBox-root css-8b3hir">
                    <p
                      className="main-frame-MuiTypography-root main-frame-MuiTypography-body1 tlMD css-fbdf7r !text-[18px] !font-[700]"
                      id="Local Lottery-d2077445-cd11-4ff5-81b3-8fc9bc24ef44-name"
                    >
                      XỔ SỐ MIỀN TRUNG
                    </p>
                  </div>
                </div>
                <div className="main-frame-MuiStack-root css-17o253n">
                  <button
                    className="main-frame-MuiButtonBase-root main-frame-MuiButton-root main-frame-MuiButton-contained main-frame-MuiButton-containedPrimary main-frame-MuiButton-sizeSm main-frame-MuiButton-containedSizeSm main-frame-MuiButton-root main-frame-MuiButton-contained main-frame-MuiButton-containedPrimary main-frame-MuiButton-sizeSm main-frame-MuiButton-containedSizeSm buttonXS css-1av77hb"
                    tabIndex={0}
                    type="button"
                    id="Local Lottery-d2077445-cd11-4ff5-81b3-8fc9bc24ef44-play-now"
                  >
                    Chơi Ngay
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container lx-intro">
          <div className="row">
            <div
              className={clsx("lx-short-draw-results !w-full", {
                "!w-full": breakpoint === "mobile",
              })}
            >
              <div className="draw-results-main !w-full md:w-[300px] !h-full">
                <h2 className="header-title">Kết quả xổ số</h2>
                <div className="draw-results">
                  {
                    datas?.map((i: any) => (
                      <div className="draw-item">
                        <div className="pr-2 draw-item-content">
                          <span className="name">{i?.stationName}</span>
                          <span className="text-right date">{i?.drawTime}</span>
                        </div>
                        <div className="mt-2">
                          {
                            i?.specialPrize?.map((a: any) => (
                              <span className="number">{a}</span>
                            ))
                          }


                        </div>
                      </div>
                    ))
                  }


                </div>

              </div>
            </div>
            <div
              className={clsx("lx-feed-list static sm:absolute", {
                "!w-full": breakpoint === "mobile",
              })}
            >
              {/* <div className="row">
                <div className="feed-item col-md-4">
                  <img
                    src="images/lx88/feeds/xosonhanh.svg"
                    alt="Lixi88 Đề Cào"
                  />
                  <h2 className="title-heading">XỔ SỐ SIÊU TỐC</h2>
                  <p>
                    Xổ số siêu tốc là một kiểu chơi đánh đề còn rất mới mẻ ở
                    Việt Nam. Ưu điểm của loại hình này là người chơi sẽ được
                    biết kết quả thắng thua rất nhanh với 3 kiểu chơi tương ứng
                    là 45 giây, 60 giây và 90 giây.
                  </p>
                  <a
                    className="nav-link-to-all check__link flex items-center"
                    href="/main.shtml?xoso=50"
                  >
                    Chơi ngay
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="size-6"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </a>
                </div>
                <div className="feed-item col-md-4">
                  <img
                    src="images/lx88/feeds/lodetruyenthong.svg"
                    alt="Lixi88 Đề Cào"
                  />
                  <h2 className="title-heading">LÔ ĐỀ TRUYỀN THỐNG</h2>
                  <p>
                    Lixi88 có đầy đủ các chế độ chơi lô đề 3 miền Bắc, Trung và
                    Nam với thể thức đa dạng, tỉ lệ trả thưởng cao.
                  </p>
                  <a
                    className="nav-link-to-all check__link flex"
                    href="/main.shtml?xoso=200"
                  >
                    Chơi ngay
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="size-6"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </a>
                </div>
                <div className="feed-item col-md-4">
                  <img src="images/lx88/feeds/decao.png" alt="Lixi88 Đề Cào" />
                  <h2 className="title-heading">LIVE CASINO</h2>
                  <p>
                    Live Casino đã có trên lixi88.Hãy tham gia và trải nghiệm
                    các trò chơi thú vị ...
                  </p>
                  <a
                    className="nav-link-to-all check__link flex"
                    href="/livecasino.shtml"
                  >
                    Chơi ngay
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="size-6"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </a>
                </div>
                <div className="feed-item col-md-4">
                  <img
                    src="images/lx88/feeds/naptien.png"
                    alt="Lixi88 Đề Cào"
                  />
                  <h2 className="title-heading">KHUYẾN MÃI</h2>
                  <p>
                    Thông tin chi tiết về chương trình khuyến mại và ưu đãi dành
                    cho người chơi.
                  </p>
                  <a
                    href="/promotions.shtml?promoID=221"
                    className="nav-link-to-all flex"
                  >
                    Hướng dẫn{" "}
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="size-6"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </a>
                </div>
                <div className="feed-item col-md-4">
                  <img src="images/lx88/feeds/daily.svg" alt="Lixi88 Đề Cào" />
                  <h2 className="title-heading">CHÍNH SÁCH ĐẠI LÝ</h2>
                  <p>
                    Thông tin chi tiết về chính sách và ưu đãi dành cho đại lý.
                  </p>
                  <a
                    href="/page/AgencyPolicy.shtml?promoID=5"
                    className="nav-link-to-all check__link flex"
                  >
                    Hướng dẫn{" "}
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="size-6"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </a>
                </div>
                <div className="feed-item col-md-4">
                  <img src="images/lx88/feeds/faq.png" alt="Lixi88 Đề Cào" />
                  <h2 className="title-heading">CÂU HỎI THƯỜNG GẶP?</h2>
                  <p>Giải đáp câu hỏi người chơi.</p>
                  <a
                    className="nav-link-to-all check__link flex"
                    href="/page/question.shtml"
                  >
                    Hướng dẫn{" "}
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="size-6"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </a>
                </div>
              </div> */}
            </div>
          </div>
          <div className="row lx-feature">
            <a
              href="/lixi88.zip"
              className="feature-item text-center col-md-4 down__app"
            >
              <img
                src="images/lx88/features/feature-01.svg"
                alt="Lixi88 Công nghệ bảo mật"
              />
              <h3>
                {" "}
                PHIÊN BẢN DÀNH CHO WINDOWS <br /> BẢN PRO{" "}
              </h3>
            </a>
            <a
              href="javascript:void(0);"
              className="feature-item text-center col-md-4 down__appmobile"
            >
              <span className="new">
                <img src="./images/lx88/common/new.gif" />
              </span>
              <img
                src="images/lx88/features/feature-02.svg"
                alt="Lixi88 Công nghệ bảo mật"
              />
              <h3>
                {" "}
                PHIÊN BẢN DÀNH CHO ĐIỆN THOẠI <br /> iOS, ANDROID và WEB{" "}
              </h3>
            </a>
            <div className="feature-item text-center col-md-4">
              <img src="images/lx88/feeds/faq.png" alt="Lixi88 Đề Cào" />
              <h3>
                {" "}
                CHĂM SÓC KHÁCH HÀNG <br /> 24/24{" "}
              </h3>
            </div>
          </div>
        </div>
        <div id="footer">
          <div className="ld-footer-inner">
            {/* Certificate, Social link & Register link*/}
            <div className="container">
              <div className="row align-items-center">
                <div className="certificate col-md-6">
                  <img
                    src="/images/lx88/common/comodo.png"
                    alt="Chứng chỉ bảo mật SSL của Comodo và các phương thức thanh toán trên Lixi88."
                    title="Chứng chỉ bảo mật SSL của Comodo và các phương thức thanh toán trên Lixi88."
                  />
                  <img
                    className="d-block mb-2 instant-logo"
                    src="/images/lx88/common/IL_logo.png"
                    alt="Kết quả xổ số nhanh trên Lixi88 được cung cấp và cho phép sử dụng bởi website InstantLottery.co"
                    title="Kết quả xổ số nhanh trên Lixi88 được cung cấp và cho phép sử dụng bởi website InstantLottery.co"
                  />
                  <div className="logo__footer">
                    <img src="/images/logo-tst-pagcor.png?1722668042245" />
                  </div>
                  <p className="color-64">
                    All rights reserved © Copyright 2018
                  </p>
                </div>
                <div className="col-md-6">
                  <ul className="nav-social text-right position-relative">
                    <li className="nav-social-item">
                      <a
                        className="nav-social-item-link face-book"
                        href="https://facebook.com/lixi88vn"
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    </li>
                    <li className="nav-social-item">
                      <a
                        className="nav-social-item-link google-plus"
                        href="https://plus.google.com/b/113577012899025477748/113577012899025477748"
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    </li>
                    <li className="nav-social-item">
                      <a
                        className="nav-social-item-link youtube"
                        href="https://www.youtube.com/channel/UCxKAfhU-TEvh-wDd91wWKMA"
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    </li>
                    <li className="nav-social-item">
                      <a className="nav-social-item-link viber" />
                    </li>
                    <li className="nav-social-item">
                      <a
                        className="nav-social-item-link zalo"
                        href="https://zalo.me/2018834966462934115"
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    </li>
                  </ul>
                  <div className="text-right mt-2">
                    <img
                      src="/images/lx88/common/register-parter.png"
                      alt="Click để đăng ký làm đối tác của Lixi88."
                      title="Click để đăng ký làm đối tác của Lixi88."
                    />
                  </div>
                  <div className="tbl mgT-20">
                    <div className="tbl__row">
                      <div className="tbl__col text-center w-20 ver-c">
                        <img src="/images/18+.png?1722668042245" />
                      </div>
                      <div className="tbl__col">
                        <p className="lh-22 text-justify color-64">
                          Chúng tôi tích cực đẩy mạnh cá cược có trách nhiệm và
                          cương quyết từ chối trẻ vị thành niên sử dụng phần mềm
                          của chúng tôi để cá cược trên mạng.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="back-to-top text-center back__top">
                <a href="javascript:void(0)" />
              </div>
            </div>
          </div>
          <div className="bottom-link">
            <div className="row">
              <ul className="nav nav-bottom">
                <li className="nav-bottom-item">
                  <a href="/page/AboutUs.shtml">Về chúng tôi</a>
                </li>
                <li className="nav-bottom-item">
                  {" "}
                  <a href="/page/GammerResponsibility.shtml">
                    Cờ bạc có trách nhiệm
                  </a>{" "}
                </li>
                <li className="nav-bottom-item">
                  {" "}
                  <a href="/page/Copyright.shtml">Tuyên bố bản quyền</a>{" "}
                </li>
                <li className="nav-bottom-item">
                  {" "}
                  <a href="/page/PrivacyPolicy.shtml">
                    Chính sách bảo mật
                  </a>{" "}
                </li>
                <li className="nav-bottom-item">
                  {" "}
                  <a href="/page/RulesAndRegulations.shtml">
                    Quy định và luật lệ
                  </a>{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
