import { Spin } from "antd";
import requestService from "api/request";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "store";

const Team = () => {
  const [datas, setData] = useState<any>();
  const { user } = useAppSelector((state) => state.app) as any;
  const [loading,setLoading] = useState(false)

  const getMemberLevel = async () => {
    setLoading(true)
    try {
      const res = await requestService.get(
        "/profile//members-count/" + user?._id
      );
      if (res && res.data) {
        setData(res.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false)
  };

  useEffect(() => {
    if (user) getMemberLevel();
  }, [user]);

  return (
    <div className="pb-[100px] px-4">
      <Spin spinning={loading} fullscreen />
      <h3 className="text-[#fff] text-[18px] font-[700] mb-4">
        Thông tin hệ thống
      </h3>
      <div className="grid grid-cols-2 gap-2">
        <div className="text-[#000] bg-gray-400 rounded-lg p-1.5">
          <div className="text-[15px] text-[#000] font-[600]">
            Tổng số thành viên
          </div>
          <div>{datas?.totalMembers || "-"}</div>
        </div>
        <div className="text-[#000] bg-gray-400 rounded-lg p-1.5">
          <div className="text-[15px] text-[#000] font-[600]">
            Tổng hoa hồng
          </div>
          <div>
            {datas?.totalProfit || "0"}$
          </div>
        </div>
      </div>
      <div>
        <div className="text-[15px] text-[#aaa] text-center mt-4">
          Số thành viên từng cấp
        </div>
        <div className="relative overflow-x-auto mt-4">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-white uppercase bg-gray-600">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Cấp
                </th>
                <th scope="col" className="px-6 py-3">
                  Số lượng
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-gray-500 border-b text-[#cccc]">
                <th
                  scope="row"
                  className="px-6 py-4 font-[900]  whitespace-nowrap "
                >
                  1
                </th>
                <td className="px-6 py-4">{datas?.levels[0] || "-"}</td>
              </tr>
              <tr className="bg-gray-500 border-b text-[#cccc]">
                <th
                  scope="row"
                  className="px-6 py-4 font-[900]  whitespace-nowrap "
                >
                  2
                </th>
                <td className="px-6 py-4">{datas?.levels[1] || "-"}</td>
              </tr>
              <tr className="bg-gray-500 border-b text-[#cccc]">
                <th
                  scope="row"
                  className="px-6 py-4 font-[900]  whitespace-nowrap "
                >
                  3
                </th>
                <td className="px-6 py-4">{datas?.levels[2] || "-"}</td>
              </tr>
              <tr className="bg-gray-500 border-b text-[#cccc]">
                <th
                  scope="row"
                  className="px-6 py-4 font-[900]  whitespace-nowrap "
                >
                  4
                </th>
                <td className="px-6 py-4">{datas?.levels[3] || "-"}</td>
              </tr>
              <tr className="bg-gray-500 border-b text-[#cccc]">
                <th
                  scope="row"
                  className="px-6 py-4 font-[900]  whitespace-nowrap "
                >
                  5
                </th>
                <td className="px-6 py-4">{datas?.levels[4] || "-"}</td>
              </tr>
              <tr className="bg-gray-500 border-b text-[#cccc]">
                <th
                  scope="row"
                  className="px-6 py-4 font-[900]  whitespace-nowrap "
                >
                  6
                </th>
                <td className="px-6 py-4">{datas?.levels[5] || "-"}</td>
              </tr>
              <tr className="bg-gray-500 border-b text-[#cccc]">
                <th
                  scope="row"
                  className="px-6 py-4 font-[900]  whitespace-nowrap "
                >
                  7
                </th>
                <td className="px-6 py-4">{datas?.levels[6] || "-"}</td>
              </tr>
              <tr className="bg-gray-500 border-b text-[#cccc]">
                <th
                  scope="row"
                  className="px-6 py-4 font-[900]  whitespace-nowrap "
                >
                  8
                </th>
                <td className="px-6 py-4">{datas?.levels[7] || "-"}</td>
              </tr>
              <tr className="bg-gray-500 border-b text-[#cccc]">
                <th
                  scope="row"
                  className="px-6 py-4 font-[900]  whitespace-nowrap "
                >
                  9
                </th>
                <td className="px-6 py-4">{datas?.levels[8] || "-"}</td>
              </tr>
              <tr className="bg-gray-500 border-b text-[#cccc]">
                <th
                  scope="row"
                  className="px-6 py-4 font-[900]  whitespace-nowrap "
                >
                  10
                </th>
                <td className="px-6 py-4">{datas?.levels[9] || "-"}</td>
              </tr>
              <tr className="bg-gray-500 border-b text-[#cccc]">
                <th
                  scope="row"
                  className="px-6 py-4 font-[900]  whitespace-nowrap "
                >
                  11
                </th>
                <td className="px-6 py-4">{datas?.levels[10] || "-"}</td>
              </tr>
              <tr className="bg-gray-500 border-b text-[#cccc]">
                <th
                  scope="row"
                  className="px-6 py-4 font-[900]  whitespace-nowrap "
                >
                  12
                </th>
                <td className="px-6 py-4">{datas?.levels[11] || "-"}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Team;
