import React from 'react'
import { Link } from 'react-router-dom'

const Withdraw = () => {
    return (
        <>
            <div className="app1">
                <div className="header_profile">
                    <div className="info_profile">
                        <div></div>
                        <div className="avatar_profile">
                            <div className="name_profile"></div>

                            <div
                                style={{
                                    marginLeft: "20px",
                                    fontSize: "1.2rem",
                                    fontWeight: 600,
                                    color: "white"
                                }}>
                                Rút điểm
                            </div>
                        </div>
                        <Link to="/mine" className="icon_setting_header">
                            &times;
                        </Link>
                    </div>
                </div>
                <div className="content_profile">
                    {/*<img
						style={{ width: "40%", marginTop: "20px" }}
						src="https://play-lh.googleusercontent.com/ufwUy4SGVTqCs8fcp6Ajxfpae0bNImN1Rq2cXUjWI7jlmNMCsXgQE5C3yUEzBu5Gadkz"
						alt=""
					/>
					<div style={{color:"red", fontWeight:600}}>Người nhận: usertest2</div>
								<div style={{color:"red",fontWeight:600}}>Vietcombank 1231231231 </div>*/}
                    <form style={{ display: "block", padding: "15px", textAlign: "left" }} >
                        <div>
                            Số điểm hiện có: {Math.floor(10000).toLocaleString()} đ
                            <div>
                                <input className="ipadd" type="number" placeholder="Nhập số điểm cần rút" />
                            </div>
                            <div style={{ display: "none" }}>
                                {/*<textarea className="ipadd" {...register("content", { required: false })} placeholder="Ghi chú" rows="4"></textarea>
										<input
									className="ipadd"
									type="text"
									{...register("stk", { required: true })}
									placeholder="Nhập số tài khoản"
								/>
								<input
									className="ipadd"
									type="text"
									{...register("bank", { required: true })}
									placeholder="Nhập tên ngân hàng"
								/>
								<input
									className="ipadd"
									type="text"
									{...register("name", { required: true })}
									placeholder="Nhập tên chủ tài khoản"
								/>
								
								<select style={{color: "#333"}}
									{...register("detail", { required: true })}
								>
									<option value="">Chọn ngân hàng</option>
									{bank.map((item, index) => (
										<option
											key={index}
											value={item.name_bank + ' - ' + item.fullname + ' - ' + item.stk}
										>
											{"STK " + item.stk + " - " + item.fullname + " - " + item.name_bank}
										</option>
									))}
									</select>*/}
                            </div>
                            <button type="submit" className="btn-submit">
                                Xác nhận
                            </button>

                        </div>
                    </form>
                </div>

            </div>
        </>
    )
}

export default Withdraw